import React, { useState } from "react";
import styled from 'styled-components';

import Logo from 'assets/capital-relacional.png';

import { Header } from './atoms';

export const NAV = styled.nav`
  nav {
    padding-top: 50px;
    padding-bottom: 10px;
    text-transform: uppercase;
  }

  @media(max-width: 800px) {
    display: ${props => props.showMenu ? 'block' : 'none'};
    position: fixed;
    background: #0F355F;
    top: 100px;
    left: 0;
    padding: 10px;
    z-index:99;
    width: 100vw;

    nav {
      padding: 0;
      display: flex;
      flex-direction: column;

      a {
        font-size: 20px;
        padding: 10px 0;
        
        + a {
          border-top: 1px solid #1F7CBF;
        }
      }
    }
  }
`;

function HeaderComponent() {
  const [showMenu, toogleMenu] = useState();

  return (
    <Header>
      <img src={Logo} alt="Capital Relacional" />
      <div>
        <a className="icon mobile-menu" onClick={() => toogleMenu(!showMenu)}>
          <i className="fa fa-bars"></i>
        </a>
        <NAV showMenu={showMenu}>
          <nav>
            <a href="/#/login">Plataforma</a>
            <a href="/#/">Onboarding</a>
            <a href="/#/sobre-nos">Sobre nós...</a>
            <a href="/#/login">Entrar</a>
          </nav>
        </NAV>
      </div>
    </Header>
  );
};

export default HeaderComponent;
