import React, { useLayoutEffect, useRef } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../utils";
import { definirLocalizacaoAmCharts, formatarEixoXAmCharts, formatarEixoYAmCharts } from "../../utils/utils-amcharts";

const Graph = ({ graphData }) => {
  const dadosGrafico = graphData || [];
  const chartReferencia = useRef(null);
  useLayoutEffect(() => {
    if (dadosGrafico.length > 0) {
      const coresRelacao = {
        "success": "#34c38f",
        "warning": "#f1b44c",
        "danger": "#f46a6a"
      }

      const cores = [
        "#264653",
        "#E97C61",
        "#E9C46A",
        "#8AB17D",
        "#277DA1",
        "#2A9D8F",
        "#E76F51",
        "#287271",
        "#EFB366",
        "#EE8959"
      ]

      let maxValor = 0;
      let minValor = 0;

      dadosGrafico.map(arr => {
        maxValor = arr.relacao_resultado > maxValor ? arr.relacao_resultado : maxValor;
        minValor = arr.relacao_resultado < minValor ? arr.relacao_resultado : minValor;
      });

      let data = [];

      let series = [...new Set(dadosGrafico.map(item => item.icr_id_agente_holograma))];
      let periodos = [...new Set(dadosGrafico.map(item => item.icr_nome_periodo_resposta))];

      periodos.map(periodo => {
        data.push({
          periodo
        })
        var atual = data[data.length - 1];
        series.map(serie => {
          let valor = dadosGrafico.filter(a => a.icr_nome_periodo_resposta == periodo && a.icr_id_agente_holograma == serie)
          atual[`valor_${serie}`] = valor[0]?.relacao_resultado || 0
          atual[`icr_${serie}`] = valor[0]?.relacao_icr || ""
          atual[`relacao_${serie}_cor`] = coresRelacao[valor[0]?.relacao_cor] || ""
        });
      })

      var chart = am4core.create("chartdiv", am4charts.XYChart);

      chartReferencia.current = chart;
      definirLocalizacaoAmCharts(chart);
      chart.data = data;

      const eixoY = formatarEixoYAmCharts(chart.yAxes.push(new am4charts.ValueAxis()), maxValor, "", minValor);
      eixoY.renderer.labels.template.disabled = true;
      eixoY.renderer.grid.template.strokeOpacity = 0;

      const dateAxis = formatarEixoXAmCharts(chart.xAxes.push(new am4charts.CategoryAxis()), { categoria: "periodo" });
      dateAxis.renderer.grid.template.strokeOpacity = 0;
      chart.legend = new am4charts.Legend();
      chart.legend.position = 'top';
      chart.legend.itemContainers.template.paddingTop = 0;

      series.map((item, i) => {
        let nome = dadosGrafico.filter(a => a.icr_id_agente_holograma == item)[0].icr_descricao;
        var serie = chart.series.push(new am4charts.LineSeries());
        serie.dataFields.valueY = `valor_` + item;
        serie.dataFields.categoryX = "periodo";
        serie.name = nome;
        serie.stroke = am4core.color(cores[i]);
        serie.fill = am4core.color(cores[i]);
        serie.strokeWidth = 2;

        const bullet = new am4charts.CircleBullet();
        bullet.circle.fillOpacity = 1;
        bullet.circle.radius = 3;
        bullet.circle.fill = am4core.color(cores[i]);
        bullet.circle.stroke = am4core.color(cores[i]);
        bullet.circle.strokeWidth = 2;
        serie.bullets.push(bullet);

        var valueLabel = serie.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.fontSize = 20;
        valueLabel.label.propertyFields.fill = `relacao_${item}_cor`;
        valueLabel.label.background.fillOpacity = 0;
        valueLabel.label.paddingRight = 5;
        valueLabel.label.paddingLeft = 5;
        valueLabel.label.paddingTop = 3;
        valueLabel.label.dy = -18;

        valueLabel.label.text = `[bold font-size: 20px]{${`icr_` + item}}[/]`
      });

      return () => {
        chart.dispose();
      };
    }
  }, [graphData])

  let alturaFinal = "";

  if (window.outerHeight < 600) {
    alturaFinal = `calc(100vh)`;
  } else {
    alturaFinal = `calc(100vh - 100px)`;
  }

  return (
    <div id="chartdiv" style={{ width: "100%", minHeight: alturaFinal }}></div>
  )
};

export default React.memo(Graph);
