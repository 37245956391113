import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
// import logo from 'assets/logo.svg';
// import logoInverse from 'assets/logo-inverse.svg';

const HeaderContainer = styled.div`
  margin-bottom: 10vh;
`;

// const HeaderLogo = styled.img`
//   max-height: 28px;
//   height: 28px;

//   :hover {
//     opacity: 0.8;
//   }
// `;

function HeaderComponent({
  variant,
}) {
  return (
    <HeaderContainer>
      <Link to="/">
        {/* <HeaderLogo src={variant === 'default' ? logo : logoInverse} alt="Capital Relacional" /> */}
      </Link>
    </HeaderContainer>
  )
};

HeaderComponent.defaultProps = {
  variant: 'default'
};

export default HeaderComponent;
