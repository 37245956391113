import React, { useState, useContext }  from 'react';
import styled from 'styled-components';
import InternalLayout from 'components/InternalLayout';
import Loading from 'components/Loading';
import Graph from './Graph'
import Filters from 'containers/Filters/AgentsAndCamada'
import '@bryntum/grid/grid.stockholm.css';

import { RelativeIndicatorContext } from 'context/RelativeIndicator';

const Content = styled.div`
  > div {
    height: calc(100vh - 70px);
  }
`

const CenterAgents = styled.h4`
  text-align: center;
  padding-top: 0px;
  height: 40px;
  padding: 10px 0;
`

const agentes = {
  1: 'Clientes',
  2: 'Colaboradores',
  5: 'Líderes',
  8: 'Consumidores finais',
  9: 'Franqueados',
  10:	'Jarinas',
  11:	'Jarininhas',
  12:	'Lojistas',
  13:	'Desembarques',
  14:	'Cooperados Gestão Agro',
  15:	'Cooperados Gestão Empresarial',
  16:	'Cooperados Gestão Pessoal',
  17:	'Supervisores',
  18:	'Gerentes CA',
  19:	'Gerentes Agência'
}

function RelationalCompetencePage() {
  const {
    dadosAgora, getDadosAgora,
  } = useContext(RelativeIndicatorContext);
  const [usedSearch, setUsedSearch] = useState();
  const [camadasSelectionadas, setCamadasSelectionadas] = useState();

  return (
    <InternalLayout
      title="Competência relacional"
      toogledSearch={usedSearch}
      filters={
        <Filters onSearch={(payload, camadas) => {
          getDadosAgora({
            ...payload,
          });
          setUsedSearch(!usedSearch);

          let agentesCamada = ['Todas as Camadas'];
          if (payload.idAgenteHolograma) {
            agentesCamada = [];

            agentesCamada.push(agentes[payload.idAgenteHolograma])

            if (payload.idCamada && camadas) {
              const selected = payload.idCamada.split('|;');
              selected.forEach(element => {
                const camadaSelecionada =  camadas.find(item => item.value == element);

                if (camadaSelecionada) {
                  agentesCamada.push(camadaSelecionada.label)
                }
              });
            }
          }
          setCamadasSelectionadas(agentesCamada);
        }} />
      }
    >
      <Content>
        {(dadosAgora.loading) ? (
          <Loading show={dadosAgora.loading} size="big" />
        ) : dadosAgora && !dadosAgora.length ? (
          <p style={{margin: 20}}>Sem resultados para a busca</p>
        ) : (
          <div>
            {camadasSelectionadas && (
              <CenterAgents>{camadasSelectionadas.join(' - ')}</CenterAgents>
            )}
            <Graph
              graphData={dadosAgora}
            />
          </div>
        )}
      </Content>
    </InternalLayout>
  );
}

export default RelationalCompetencePage;
