import React, { useContext, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { AuthContext } from 'context/Auth';
import {
  AuthContainer, AuthFormContainer,
  AuthImageContainer, AuthFormContent,
  AuthFormTitle,
  DefaultLink, ButtonsBox,
} from 'styles/AuthStyles';
import LoginPortrait from 'components/LoginPortrait';
import EmailInput from 'components/Input/Email';
import PasswordInput from 'components/Input/Password';
import Button from 'components/Button';
import Loading from 'components/Loading';
import ErrorBox from 'components/ErrorBox';
import LogoLink from 'components/LogoLink';

const testEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function LoginPage() {
  const { submitLogin, loginContent, clearAuthContext } = useContext(AuthContext);
  const [email, setEmail] = useState();
  const [validEmail, setValidEmail] = useState();
  const [validForm, setValidForm] = useState();
  const [password, setPassword] = useState();
  const [response, setResponse] = useState({});

  const { loading } = loginContent;

  useEffect(() => {
    return function cleanup() {
      clearAuthContext();
    };
  }, []);

  useEffect(() => {
    if (email && email.length > 5) {
      setValidEmail(testEmail.test(email));
    } else {
      setValidEmail(false);
    }
  }, [email]);

  useEffect(() => {
    if (password && password.length > 5 && email && validEmail) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [password, email, validEmail]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const result = await submitLogin({
      email,
      password,
    });

    setResponse({
      ...result,
    });
  }

  return (
    <AuthContainer>
      {response.success && !response.action && <Redirect to="/dashboard" />}
      {response.success && response.action === 'changePassword' && <Redirect to="/trocar-senha" />}
      <Loading show={loading} size="big" />
      <AuthFormContainer>
        <AuthFormContent>
          <LogoLink variant="inverse" />
          <AuthFormTitle>Entrar</AuthFormTitle>
          {response.success === false && (
            <ErrorBox>{response.message}</ErrorBox>
          )}
          <form noValidate onSubmit={onSubmit}>
            <EmailInput
              id="email"
              name="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <PasswordInput
              id="password"
              name="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <ButtonsBox>
              <Button
                label="Entrar"
                disabled={!validForm}
              />
            </ButtonsBox>
            <DefaultLink>
              <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
            </DefaultLink>
          </form>
        </AuthFormContent>
      </AuthFormContainer>
      <AuthImageContainer>
        <LoginPortrait />
      </AuthImageContainer>
    </AuthContainer>
  );
}

export default LoginPage;
