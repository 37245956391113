import React, { useState, useEffect, useContext }  from 'react';
import styled from 'styled-components';
import { getUserData } from 'services/getUserData';
import Select from "react-select";

import { RelativeIndicatorContext } from 'context/RelativeIndicator';

const FilterWrapper = styled.div`
  width: 100%;

  > div {
    margin-bottom: 24px;
  }
`

const FilterTitle = styled.p`
  color: #FFF;
  font-weight: bold;
  margin-bottom: 10px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

const Button = styled.button`
  background: rgba(2,255,103,.4);
  border-radius: 5px;
  color: #0F355F;
  padding: 18px 64px;
  font-weight: bold;
  border: 0;
  width: 100%;

  :hover {
    opacity: .8;
  }
`;

function Filters({ onSearch }) {
  const {
    partners, getPartners,
    clients, getClients,
    periods, getPeriods,
    clearFilters,
  } = useContext(RelativeIndicatorContext);

  const [partner, setPartner] = useState();
  const [client, setClient] = useState();
  const [period, setPeriod] = useState();
  const [userData, setUserData] = useState({});

  const [firstLoaded, setFirstLoaded] = useState();

  useEffect(() => {
    // getPartners();
    setUserData(getUserData());

    return () => {
      clearFilters();
    }
  }, []);

  useEffect(() => {
    if (userData.name) {
      if (!userData.idPartner) {
        getPartners();
      } else {
        setPartner({ value: userData.idPartner })
      }
    }
  }, [userData]);

  useEffect(() => {
    if (!partners.loading) {
      setPartner(partners[0]);
    }
  }, [partners]);

  useEffect(() => {
    if (!clients.loading && periods.loading) {
      setClient(clients[0]);
    }
  }, [clients]);

  useEffect(() => {
    if (!periods.loading) {
      setPeriod(periods[0]);
      if (!firstLoaded && partner && client) {
        onSearch({
          idParceiro: partner.value,
          idCliente: client.value,
          idPeriodoResposta: periods[0]?.value,
        })
        setFirstLoaded(true);
      }
    }
  }, [periods]);

  useEffect(() => {
    if (!userData.idClient) {
      setClient();
      setPeriod();

      if (partner) {
        getClients({
          idParceiro: partner.value,
        })
      }
    } else {
      setClient({ value: userData.idClient });
    }
  }, [partner]);

  useEffect(() => {
    setPeriod();

    if (client) {
      getPeriods({
        idParceiro: partner.value,
        idCliente: client.value,
      })
    }
  }, [client]);

  return (
    <FilterWrapper>
      <FilterTitle>Selecione suas opções de filtro:</FilterTitle>
      {userData.idPartner ? null : (
        <Select
          placeholder="Parceiro"
          value={partner}
          options={partners || []}
          classNamePrefix="select2-selection"
          onChange={(Object) => {
            setPartner(Object);
          }}
        />
      )}
      {userData.idClient ? null : (
        <Select
          placeholder="Cliente"
          value={client}
          options={clients || []}
          classNamePrefix="select2-selection"
          onChange={(Object) => {
            setClient(Object);
          }}
        />
      )}
      <Select
        placeholder="Período"
        value={period}
        options={periods || []}
        classNamePrefix="select2-selection"
        // defaultValue={parceiro || []}
        onChange={(Object) => {
          setPeriod(Object);
        }}
      />
      <ButtonWrapper>
        <Button
          onClick={(e) => {
            onSearch({
              idParceiro: partner.value,
              idCliente: client.value,
              idPeriodoResposta: period.value,
            })
          }}
          disabled={false}
        >
          Aplicar filtro
        </Button>
      </ButtonWrapper>
    </FilterWrapper>
  );
}

export default Filters;
