import React from 'react';
import styled from 'styled-components'

import logoWhite from 'assets/capital-relacional.png';

import {
  ImageContainer,
  MainImage,
  IconsRelative,
} from 'styles/AuthStyles';

const MainImageContent = styled(MainImage)`

`;

function LoginPortrait() {
  return (
    <ImageContainer>
      <IconsRelative>
        <MainImageContent src={logoWhite} alt="Capital Relacional" />
      </IconsRelative>
    </ImageContainer>
  )
};

export default LoginPortrait;
