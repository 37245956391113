import React from "react";
import Human10 from 'assets/capital/human-10.svg';
import Human8 from 'assets/capital/human-8.svg';

import { Humans } from './atoms';

function Home() {

  return (
    <Humans>
      <img src={Human10} alt="Capital Relacional" />
      <img src={Human8} alt="Capital Relacional" />
      <div>
        <p>O <b>relacionamento humano</b> é, e continuará a ser, a <b>alma</b> dos <b>negócios</b>.</p>
        <p>Roberto Tranjan</p>
      </div>
    </Humans>
  );
};

export default Home;
