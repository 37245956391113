import React from "react";
import BadgeIcon from 'assets/capital/badge.svg';

import Footer from 'components/Landing/Footer';
import Contact from 'components/Landing/Contact';
import Header from 'components/Landing/Header';
import Onboarding from 'components/Landing/Onboarding';

import Usa from 'assets/capital/usa.svg';
import Brazil from 'assets/capital/brazil.svg';
import Spain from 'assets/capital/spain.svg';

import {
  Body, Badge, Button,
  Plans, Plan, BestPlan,
  Resources, Dot, ResourcesWhite,
  DotGreen, DotBlue, DotOrange,
} from './atoms';

function AboutUs() {
  const scrollToContact = () => {
    document.getElementById('contact').scrollIntoView();
  }

  return (
    <Body >
      <Header />
      <Plans>
        <Plan bg="#0F355F">
          <h3>Planos</h3>
        </Plan>
        <Plan>
          <h3>Muacy</h3>
          <p>Para empresas que optam por manter o Capital Relacional saudável para não perder resultados.</p>
        </Plan>
        <Plan bg="#1F7CBF">
          <h3>Jarina</h3>
          <p>Para empresas que preferem trabalhar o  Capital Relacional apenas na esfera operacional.</p>
        </Plan>
        <BestPlan>
          <div>MAIS ESCOLHIDO</div>
          <Badge src={BadgeIcon} alt="MAIS ESCOLHIDO" />
          <h3>Kiran</h3>
          <p>Para empresas que desejam alto rendimento do seu Capital Relacional obtendo resultados de forma estratégica.</p>
        </BestPlan>
      </Plans>
      <Resources>
        <div>
          <h3>Recursos estruturais</h3>
          <table>
            <tr>
              <th>Gestão de respondentes</th>
              <td><DotGreen /></td>
              <td><DotBlue /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Gestão de camadas de negócio</th>
              <td><Dot /></td>
              <td><DotBlue /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Idiomas do questionário</th>
              <td>
                <img src={Brazil} alt="" />
              </td>
              <td>
                <img src={Brazil} alt="" />
              </td>
              <td>
                <img src={Brazil} alt="" />
                <img src={Usa} alt="" />
                <img src={Spain} alt="" />
              </td>
            </tr>
            <tr>
              <th>Canais de envio dos convites</th>
              <td>e-mail</td>
              <td>e-mail e whatsapp</td>
              <td>e-mail e whatsapp</td>
            </tr>
            <tr>
              <th>Limite de respondentes</th>
              <td>150</td>
              <td>500</td>
              <td>ilimitado</td>
            </tr>
            <tr>
              <th>Quantidade de usuários disponíveis na licença</th>
              <td>3</td>
              <td>5</td>
              <td>10</td>
            </tr>
          </table>
        </div>
      </Resources>
      <ResourcesWhite>
        <div>
          <h3>Ferramentas relacionais</h3>
          <table>
            <tr>
              <th>Nuvem de percepções</th>
              <td><Dot /></td>
              <td><Dot /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Indicadores relacionais</th>
              <td><DotGreen /></td>
              <td><DotBlue /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Súplicas em destaque</th>
              <td><Dot /></td>
              <td><Dot /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Camadas Relacionais</th>
              <td><Dot /></td>
              <td><DotBlue /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Competência relacional</th>
              <td><Dot /></td>
              <td><Dot /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Cultura relacional</th>
              <td><Dot /></td>
              <td><Dot /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Posicionamento relacional</th>
              <td><Dot /></td>
              <td><Dot /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Painel das súplicas</th>
              <td><DotGreen /></td>
              <td><DotBlue /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Boa conversa</th>
              <td><DotGreen /></td>
              <td><DotBlue /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Histórico relacional</th>
              <td><Dot /></td>
              <td><DotBlue /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Leitura de resultados com um especialista</th>
              <td><Dot /></td>
              <td><DotBlue /></td>
              <td><DotOrange /></td>
            </tr>
          </table>
        </div>
      </ResourcesWhite>
      <Resources>
        <div>
          <h3>Tecnologia</h3>
          <table>
            <tr>
              <th>Plataforma online</th>
              <td><DotGreen /></td>
              <td><DotBlue /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Aplicativo iOS e Android</th>
              <td><Dot /></td>
              <td><DotBlue /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Canais de suporte</th>
              <td>e-mail</td>
              <td>e-mail e chat</td>
              <td>e-mail, chat e whatsapp</td>
            </tr>
          </table>
        </div>
      </Resources>
      <ResourcesWhite>
        <div>
          <h3>Conteúdo</h3>
          <table>
            <tr>
              <th>Playlist de vídeos sobre Capital Relacional</th>
              <td><DotGreen /></td>
              <td><DotBlue /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Artigos sobre o Capital Relacional</th>
              <td><DotGreen /></td>
              <td><DotBlue /></td>
              <td><DotOrange /></td>
            </tr>
            <tr>
              <th>Livro Capital Relacional de Roberto Tranjan</th>
              <td>3 exemplares</td>
              <td>5 exemplares</td>
              <td>10 exemplares</td>
            </tr>
          </table>
          <Button onClick={scrollToContact} full>QUERO UMA DEMONSTRAÇÃO</Button>
        </div>
        
      </ResourcesWhite>
      <Onboarding onClickButton={scrollToContact} />
      <div id="contact"></div>
      <Contact />
      <Footer />
    </Body>
  );
};

export default AboutUs;
