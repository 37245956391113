import React, { useState, useContext }  from 'react';
import styled from 'styled-components';
import InternalLayout from 'components/InternalLayout';
import Loading from 'components/Loading';
import Filters from 'containers/Filters';
import Relacao from 'components/Relacao';
import '@bryntum/grid/grid.stockholm.css';

import { RelativeIndicatorContext } from 'context/RelativeIndicator';

const Content = styled.div`
  margin-top: 0;
  > div {
    height: calc(100vh - 70px);
  }

  .b-gridbase:not(.b-moving-splitter)
  .b-grid-row:not(.b-group-row).b-hover
  .b-grid-cell:not(.b-focused) {
    background-color: rgba(15,53,95, 0.02) !important;
  }

  .fas {
    position: relative;
    font-size: 20px;
    &:after {
      font-size: 30px;
      content: "\f111";
      border-radius: 50px;
      width: 30px;
      height: 30px;
      opacity: 0.4;
      position: absolute;
      left: -5px;
      top: -5px;
    }
  }

  .b-grid-header-text {
    font-size: 14px;
    font-weight: 500;
  }

  .font-poppins {
    font-family: 'Poppins', sans-serif;
  }
`

function RelativeIndicatorsPage() {
  const {
    icr, getICR,
  } = useContext(RelativeIndicatorContext);
  const [usedSearch, setUsedSearch] = useState();

  return (
    <InternalLayout
      title="Indicadores relacionais"
      toogledSearch={usedSearch}
      filters={
        <Filters onSearch={(payload) => {
          getICR({
            ...payload,
            visualizacao: 'relacao',
            analiseRelacoes: 1
          });
          setUsedSearch(!usedSearch);
        }} />
      }
    >
      <Loading show={icr.loading} size="big" />
      <Content>
        {(!icr || icr.loading) ? (
          <p></p>
        ) : icr && icr.data && !icr.data.length ? (
          <p style={{margin: 20}}>Sem resultados para a busca</p>
        ) : (
          <Relacao dados={icr} />
        )}
      </Content>
    </InternalLayout>
  );
}

export default RelativeIndicatorsPage;
