import React, { useState, useContext }  from 'react';
import styled from 'styled-components';
import InternalLayout from 'components/InternalLayout';
import Loading from 'components/Loading';
import Graph from './Graph'
import Filters from 'containers/Filters/AgentsAndCamada'
import '@bryntum/grid/grid.stockholm.css';

import { RelativeIndicatorContext } from 'context/RelativeIndicator';

const Content = styled.div`
  > div {
    height: calc(100vh - 70px);
  }

  .fas {
    position: relative;
    font-size: 20px;
    &:after {
      font-size: 30px;
      content: "\f111";
      border-radius: 50px;
      width: 30px;
      height: 30px;
      opacity: 0.4;
      position: absolute;
      left: -5px;
      top: -5px;
    }
  }

  .b-grid-header-text {
    font-size: 14px;
    font-weight: 500;
  }

  .font-poppins {
    font-family: 'Poppins', sans-serif;
  }
`

function BussinessLayersPage() {
  const {
    icrCamadas, getICRCamadas,
  } = useContext(RelativeIndicatorContext);
  const [usedSearch, setUsedSearch] = useState();

  return (
    <InternalLayout
      title="Camadas Relacionais"
      toogledSearch={usedSearch}
      filters={
        <Filters onSearch={(payload) => {
          getICRCamadas({
            ...payload,
            visualizacao: 'opcao',
            analiseCamadasNegocios: 1
          });
          setUsedSearch(!usedSearch);
        }} />
      }
    >
      <Loading show={icrCamadas.loading} size="big" />
      <Content>
        {(icrCamadas.loading) ? (
          <p></p>
        ) : icrCamadas && ((icrCamadas.data && !icrCamadas.data.length) || (icrCamadas.error)) ? (
          <p style={{margin: 20}}>Sem resultados para a busca</p>
        ) : (
          <Graph
            graphData={icrCamadas}
          />
        )}
      </Content>
    </InternalLayout>
  );
}

export default BussinessLayersPage;
