import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { returnaAlturaTotalElementos, returnaTotalMedidasVerticais  } from "../../utils";

const SubLabel = styled.p`
  text-align: center;
  padding-left: 125px;
  position: relative;
  top: -35px;
  color: #CD5C5C;
  font-weight: bold;
  font-size: 20px;
`;

const Graph = ({ variant, children, graphData, ...props }) => {
  const dadosGrafico = graphData || [];

  const formatarLegenda = (suplica) => {
    if (suplica == "Conquiste a minha confiança!")
      return `  Conquiste a \nminha confiança!`;

    if (suplica == "Facilite a minha vida!")
      return ` Facilite a \nminha vida!`;

    return suplica;
  }

  useLayoutEffect(() => {
    if (dadosGrafico.length > 0) {
      document.querySelector(`#chartdiv`).innerHTML = "";
      var root = am5.Root.new("chartdiv");

      root.setThemes([
          am5themes_Animated.new(root)
      ]);

      var chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout
      }));

      var yRenderer = am5xy.AxisRendererY.new(root, {
        visible: false,
        minGridDistance: 20,
        inversed: true
      });

      yRenderer.grid.template.set("visible", false);

      var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        renderer: yRenderer,
        categoryField: "icr"
      }));

      var xRenderer = am5xy.AxisRendererX.new(root, {
        visible: false,
        minGridDistance: 30,
        opposite: true
      });

      xRenderer.grid.template.set("visible", false);

      var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: "suplica"
      }));

      var series = chart.series.push(am5xy.ColumnSeries.new(root, {
        calculateAggregates: true,
        stroke: am5.color(0xffffff),
        clustered: false,
        xAxis: xAxis,
        yAxis: yAxis,
        categoryXField: "suplica",
        categoryYField: "icr",
        valueField: "value"
      }));

      series.columns.template.setAll({
        // tooltipText: "{value}",
        strokeOpacity: 1,
        strokeWidth: 2,
        width: am5.percent(100),
        height: am5.percent(100)
      });

      series.columns.template.events.on("pointerover", function (event) {
        var di = event.target.dataItem;

        if (di && di.dataContext) {
          heatLegend.showValue(di.dataContext.value, ' ');
        }
      });

      series.events.on("datavalidated", function () {
        heatLegend.set("startValue", series.getPrivate("valueLow"));
        heatLegend.set("endValue", series.getPrivate("valueHigh"));
      });

      series.set("heatRules", [{
        target: series.columns.template,
        min: am5.color(0xf7d0d0),
        max: am5.color(0xf46a6a),
        dataField: "value",
        key: "fill"
      }]);

      var heatLegend = chart.bottomAxesContainer.children.push(am5.HeatLegend.new(root, {
        orientation: "horizontal",
        startText: "-",
        endText: "+",
        endColor: am5.color(0xf46a6a),
        startColor: am5.color(0xf7d0d0),
        marginTop: 15,
        marginBottom: 15,
      }));

      heatLegend.startLabel.setAll({
        fill: '#CD5C5C',
        position: 'absolute',
        paddingTop: 15,
        fontSize: 20,
        fontWeight: 'bold',
      });

      heatLegend.endLabel.setAll({
        fill: '#CD5C5C',
        position: 'absolute',
        paddingTop: 15,
        fontSize: 20,
        fontWeight: 'bold',
      });

      var data = [];
      dadosGrafico.map(dado => {
        for (let i = 1; i <= 8; i++) {
          data.push({
            icr: `[fontFamily: Leelawadee]${dado.icr_descricao}[/]`,
            suplica: `[fontFamily: Leelawadee]${formatarLegenda(dado[`nome_suplica_${i}`])}[/]`,
            value: dado[`suplica_retorno_${i}`],
          });
        }
      });

      series.data.setAll(data);

      let icrs = [...new Set(dadosGrafico.map(item => item.icr_descricao))];

      let ucrs_utilizados = icrs.map(item => {
          return { icr: `[fontFamily: Leelawadee]${item}[/]` }
      })

      yAxis.data.setAll(ucrs_utilizados);

      let rotulosSuplicas = [];

      for (let i = 1; i <= 8; i++) {
          rotulosSuplicas.push({ suplica: `[fontFamily: Leelawadee]${formatarLegenda(dadosGrafico[0][`nome_suplica_${i}`])}[/]` })
      }

      xAxis.data.setAll(rotulosSuplicas);

      chart.appear(1000, 100);
    }
  }, [graphData])

  let alturaFinal = ""

  if (window.outerHeight < 600) {
      alturaFinal = `calc(100vh)`;
  } else {
      alturaFinal = `calc(100vh - 135px)`;
  }

  return (
    <div>
      <div id="chartdiv" style={{ width: "100%", minHeight: alturaFinal }}></div>
      <SubLabel>Necessidade de competências</SubLabel>
    </div>
  );
};

export default React.memo(Graph);
