import { StringHelper } from "@bryntum/grid/grid.umd";
// import { toMoney } from "./utils";

export function bizFrontEnd(campo) {
  const retorno = {
    coluna: {
      icone:
        fawEstilos.colunas + " " + bizMatrizRelevanciaVisual[campo].fawIcone,
      cor: bizMatrizRelevanciaVisual[campo].corColuna,
    },
    linha: {
      icone:
        fawEstilos.linhas + " " + bizMatrizRelevanciaVisual[campo].fawIcone,
      cor: bizMatrizRelevanciaVisual[campo].corLinha,
    },
  };
  return retorno;
}

export function setarBgColor(cellElement, cor) {
  cellElement.style.color = "#fff";
  cellElement.classList.add(`bg-${cor}`);
}

export function setarFontColor(cellElement, cor) {
  //cellElement.style.color = "#fff";
  cellElement.classList.add(`text-${cor}`);
}

export function setarFontBold(cellElement) {
  cellElement.style.fontWeight = "bold";
}

export const tamanhos = {
  faturamento: 150,
  cupons: 115,
  ticketMedio: 125,
};

export const setasParaCimaOuParaBaixo = (value, htmlElemento, percentual) => {
  percentual[0] === "0"
    ? htmlElemento.classList.add("igual")
    : htmlElemento.classList.add(
      percentual[0] === "-" ? "setaBaixo" : "setaCima"
    );
  return value;
};

export const setasParaCimaOuParaBaixoComparacao = (
  valor,
  valorComparacao,
  htmlElemento
) => {
  let classe = "";
  if (valor == valorComparacao) classe = "";
  else if (valor > valorComparacao) classe = "setaCima";
  else classe = "setaBaixo";

  if (classe)
    htmlElemento.classList.add(classe);

  return valor;
};

export const tamanhoDafonte = (tamanho, valor, semPorcetangem) => {
  return `<div class="p-2 font-bold font-size-${tamanho}">${valor}${semPorcetangem ? "" : "%"
    }</div>`;
};
export const tamanhoDafontePixel = (tamanho, valor, semPorcetangem) => {
  return `<div class="p-2 font-bold" style="font-size:${tamanho}px">${valor}${semPorcetangem ? "" : "%"
    }</div>`;
};
export const retornarIndicadorRedondo = (cor) => {
  return `<div style="width:15px;height:15px;" class="bg-${cor} rounded-circle"></div>`;
};


export const montarStringToolTipComparativa = (coluna, campo, comparacao) => {
  return StringHelper.xss`${coluna}:<br/><br/> <b>${campo.toLowerCase()}%</b> em relação ao ${comparacao.toLowerCase()}.`;
};

export const montarStringTollTipRepresentativa = (percetual) => {
  return StringHelper.xss`<b>${percetual}%</b> de participação.`;
};

export const destacarCelula = (value, sinal, htmlElemento, destacar) => {
  if (!destacar) return;
  if (sinal !== "") {
    htmlElemento.classList.add(sinal === ">" ? "maior" : "menor");
  }
  return value;
};

export const setaSumario = (valor, sinal) => {
  if (!sinal) return null;
  return sinal[0] === "+"
    ? `${valor} <i class="fas fa-arrow-up setaCima_html"></i> `
    : `${valor} <i class="fas fa-arrow-down setaBaixo_html"></i> `;
};

export const setaSumarioComparacao = (valor, valorComparacao) => {
  if (valor == valorComparacao) return `<i class="fas fa-arrows-h igual"></i> `;
  else if (valor > valorComparacao)
    return `<i class="fas fa-arrow-up setaCima_html"></i> `;
  else return `<i class="fas fa-arrow-down setaBaixo_html"></i> `;
};

// Estilo do Font Awesome
const fawEstilos = {
  colunas: "fal",
  linhas: "fas",
};

// Estilo dos campos principais
const bizMatrizRelevanciaVisual = {
  faturamento: {
    fawIcone: "fa-sack-dollar",
    corColuna: "#048dc1",
    corLinha: "#048dc1",
  },
  cupons: {
    fawIcone: "fa-file-alt",
    corColuna: "#026084",
    corLinha: "#026084",
  },
  ticketMedio: {
    fawIcone: "fa-ruler-vertical",
    corColuna: "#003a50",
    corLinha: "#003042",
  },
};

export const numeroComas = function (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const numeroFormatado = function (x) {
  return parseFloat(x).toFixed(2).toString();
};

export const bizGridResponsive = `{
  small: 576,
  medium: 868,
  large: "*",
}`;
