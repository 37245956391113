import React, { useState, useEffect, useContext }  from 'react';
import styled from 'styled-components';
import { getUserData } from 'services/getUserData';
import Select from "react-select";

import { RelativeIndicatorContext } from 'context/RelativeIndicator';

const FilterWrapper = styled.div`
  width: 100%;

  > div {
    margin-bottom: 24px;
  }
`

const FilterTitle = styled.p`
  color: #FFF;
  font-weight: bold;
  margin-bottom: 10px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

const Button = styled.button`
  background: rgba(2,255,103,.4);
  border-radius: 5px;
  color: #0F355F;
  padding: 18px 64px;
  font-weight: bold;
  border: 0;

  :hover {
    opacity: .8;
  }
`;

function Filters({ onSearch }) {
  const {
    partners, getPartners,
    clients, getClients,
    agents, getAgents,
    clearFilters,
  } = useContext(RelativeIndicatorContext);

  const [partner, setPartner] = useState();
  const [client, setClient] = useState();
  const [agent, setAgent] = useState();
  const [userData, setUserData] = useState({});
  const [tipoPesquisa, setTipoPesquisa] = useState({ label: "Relação", value: "R" });
  const [firstLoaded, setFirstLoaded] = useState();

  useEffect(() => {
    setUserData(getUserData());
    // getPartners();

    return () => {
      clearFilters();
    }
  }, []);

  useEffect(() => {
    if (!partners.loading) {
      setPartner(partners[0]);
    }
  }, [partners]);

  useEffect(() => {
    if (userData.name) {
      if (!userData.idPartner) {
        getPartners();
      } else {
        setPartner({ value: userData.idPartner })
      }
    }
  }, [userData]);

  useEffect(() => {
    if (!clients.loading) {
      setClient(clients[0]);
      if (!firstLoaded) {
        onSearch({
          idParceiro: partner.value,
          idCliente: clients[0].value,
          idsAgentesHolograma: agent?.value || '',
          tipoPesquisa: tipoPesquisa?.value,
        })
        setFirstLoaded(true);
      }
    }
  }, [clients]);

  useEffect(() => {
    if (!userData.idClient) {
      setClient();
      setAgent();

      if (partner) {
        getClients({
          idParceiro: partner.value,
        })
      }
    } else {
      setClient({ value: userData.idClient });
      onSearch({
        idParceiro: partner.value,
        idCliente: userData.idClient,
        idsAgentesHolograma: '',
        tipoPesquisa: tipoPesquisa?.value,
      })
      setFirstLoaded(true);
    }
  }, [partner]);

  useEffect(() => {
    setAgent();

    if (client) {
      getAgents({
        idParceiro: partner.value,
        idCliente: client.value,
      })
    }
  }, [client]);

  return (
    <FilterWrapper>
      <FilterTitle>Selecione suas opções de filtro:</FilterTitle>
      {userData.idPartner ? null : (
        <Select
          placeholder="Parceiro"
          value={partner}
          options={partners || []}
          classNamePrefix="select2-selection"
          onChange={(Object) => {
            setPartner(Object);
          }}
        />
      )}
      {userData.idClient ? null : (
        <Select
          placeholder="Cliente"
          value={client}
          options={clients || []}
          classNamePrefix="select2-selection"
          onChange={(Object) => {
            setClient(Object);
          }}
        />
      )}
      <Select
        placeholder="Tipo de pesquisa"
        value={tipoPesquisa}
        options={[
          { label: "Relação", value: "R" },
          { label: "Suplicas", value: "S" }
        ]}
        classNamePrefix="select2-selection"
        // defaultValue={parceiro || []}
        onChange={(Object) => {
          setTipoPesquisa(Object);
        }}
      />
      {tipoPesquisa.value == "S" && (
        <Select
          placeholder="Todas as relações"
          // value={parceiro}
          options={agents || []}
          classNamePrefix="select2-selection"
          isMulti
          onChange={(Object) => {
            setAgent(Object);
          }}
        />
      )}
      <ButtonWrapper>
        <Button
          onClick={(e) => {
            let idsAgentesHolograma = null;

            if (agent && agent.length) {
              idsAgentesHolograma = agent.map(item => item.value).join('|;') + '|;'
            }

            onSearch({
              idParceiro: partner.value,
              idCliente: client.value,
              idsAgentesHolograma: idsAgentesHolograma || '',
              tipoPesquisa: tipoPesquisa?.value,
            })
          }}
          disabled={false}
        >
          Aplicar filtro
        </Button>
      </ButtonWrapper>
    </FilterWrapper>
  );
}

export default Filters;
