import React, { useState } from 'react';
import styled from 'styled-components';
import logo from 'assets/capital/icone-cr.svg';
import close from 'assets/capital/window-close.svg';
import filtersLogo from 'assets/capital/search.svg';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Menu = styled.header`
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  background: #0F355F;
  z-index: 5;
`;

const MenuLogoWrapper = styled.div`
  padding: 16px 0;
  display: flex;
`;

const MenuLogo = styled.img`
  cursor: pointer;
  height: 40px;

  :hover {
    opacity: 0.8;
  }
`;

const MenuWrapper = styled.div`
  max-width: 98%;
  padding: 0 10px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;

const PageTitle = styled.h1`
  color: #fff;
  font-size: 28px;
  margin: 0;
  padding-left: 18px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  z-index: -1;
`

const FilterButton = styled.img`
  margin-top: 8px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const Leftwrapper = styled.div`
  display: flex;

  div {
    line-height: 58px;
  }

  a {
    font-size: 50px;
    margin-left: 20px;
    color: #EEE;
    line-height: 58px;

    :hover {
      opacity: 0.8;
    }
  }
`;

function MenuComponent({
  variant, title, onClickFilter,
}) {
  const [opened, setOpened] = useState(false);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title ? `Capital Relacional - ${title}` : 'Capital Relacional'}</title>
      </Helmet>
      <Menu opened={opened} variant={variant}>
        <MenuWrapper>
          <MenuLogoWrapper>
            <a href="/#/dashboard">
              <MenuLogo src={logo} alt="Capital Relacional" />
            </a>
            <PageTitle>{title}</PageTitle>
          </MenuLogoWrapper>
          <Leftwrapper>
            <div>
              <FilterButton onClick={() => onClickFilter()} src={filtersLogo} alt="Filtros" />
            </div>
            <div>
              <a href="/#/dashboard">
                <img src={close} alt="fechar" />
              </a>
            </div>
          </Leftwrapper>
        </MenuWrapper>
      </Menu>
    </HelmetProvider>
  )
};

MenuComponent.defaultProps = {
  variant: 'default'
};

export default MenuComponent;
