import React, { useState, useContext, useEffect, useRef }  from 'react';
import { getUserData, getRefreshToken } from 'services/getUserData';
import styled from 'styled-components';
import InternalLayout from 'components/InternalLayout';
import Loading from 'components/Loading';
import Filters from 'containers/Filters/AgentsAndCamadaNegocio'
import Graph from './Graph'
import '@bryntum/grid/grid.stockholm.css';
import { ExportButton, exportToPDF } from '../pdfExporter/pdfExporter'; // Adicionando a importação

import { RelativeIndicatorContext } from 'context/RelativeIndicator';

const Content = styled.div`
  margin-top: 10px;
  > div {
    height: calc(100vh - 75px);
  }

  .border2 {
    border: 2px solid #eff2f7;
  }

  .col-sm-2 .border2 .card-body {
    padding-bottom: 16px;
  }

  .font-poppins {
    font-family: 'Poppins', sans-serif;
  }
`

function OportunityCloudPage() {
  const [user, setUser] = useState(null);
  const contentRef = useRef();

  useEffect(() => {
    getRefreshToken();
    setUser(getUserData());
  }, []);

  useEffect(() => {
    if (user) {
      if (!user.hasOportunityCloudPage) {
        window.location = '#/login';
      }
    }
  }, [user]);

  const {
    icr, getICR,
    cloudData, getCloudData,
  } = useContext(RelativeIndicatorContext);
  const [usedSearch, setUsedSearch] = useState();

  return (
    <InternalLayout
      title="Nuvem de percepções"
      toogledSearch={usedSearch}
      filters={
        <Filters onSearch={(payload) => {
          getICR({
            ...payload,
            visualizacao: 'relacao',
            analiseRelacoes: 1
          });
          getCloudData({
            ...payload,
          });
          setUsedSearch(!usedSearch);
        }} />
      }
    >
      <Loading show={icr.loading} size="big" />
      {icr && icr.data && icr.data.length > 0 && (
        <ExportButton onClick={() => exportToPDF('content', false, "nuvem_de_percepcoes.pdf")}>Exportar</ExportButton>
      )}
      <Content id="content" ref={contentRef}>
        {(icr.loading) ? (
          <p></p>
        ) : icr && (!icr.data || !icr.data.length) ? (
          <p style={{margin: 20}}>Sem resultados para a busca</p>
        ) : (
          <Graph
            graphData={icr}
            cloudData={cloudData.loading ? null : cloudData}
          />
        )}
      </Content>
    </InternalLayout>
  );
}

export default OportunityCloudPage;
