import React, { useState, useEffect, useContext }  from 'react';
import styled from 'styled-components';
import { getUserData } from 'services/getUserData';
import Select from "react-select";

import { RelativeIndicatorContext } from 'context/RelativeIndicator';
import { object } from '@amcharts/amcharts5';
import { set } from 'draft-js/lib/EditorState';

const FilterWrapper = styled.div`
  width: 100%;

  > div {
    margin-bottom: 24px;
  }
`

const FilterTitle = styled.p`
  color: #FFF;
  font-weight: bold;
  margin-bottom: 10px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

const Button = styled.button`
  background: rgba(2,255,103,.4);
  border-radius: 5px;
  color: #0F355F;
  padding: 18px 64px;
  font-weight: bold;
  border: 0;
  width: 100%;

  :hover {
    opacity: .8;
  }
`;

const types = [{
  value: 'R',
  label: 'Por respondentes',
}, {
  value: 'C',
  label: 'Por camadas de negócio',
},]

function Filters({ onSearch, onAgents = () => {}, onlyAgents, changeType }) {
  const {
    partners, getPartners,
    clients, getClients,
    periods, getPeriods,
    agents, getAgents,
    camadas, getCamadas,
    camadasNegocio, getCamadasNegocio,
    clearFilters,
  } = useContext(RelativeIndicatorContext);

  const [partner, setPartner] = useState();
  const [client, setClient] = useState();
  const [period, setPeriod] = useState();
  const [agent, setAgent] = useState();
  const [colab, setColab] = useState();
  const [leaders, setLeaders] = useState();
  const [clien, setClien] = useState();
  const [franchise, setFranchise] = useState();
  const [userData, setUserData] = useState({});

  const [consumidorFinal, setConsumidorFinal] = useState();
  const [jarina, setJarina] = useState();

  const [supervisor, setSupervisor] = useState();
  const [gerenteCa, setGerenteCa] = useState();
  const [gerenteAgencia, setGerenteAgencia] = useState();

  const [coopAgro, setCoopAgro] = useState();
  const [coopEmpr, setCoopEmpr] = useState();
  const [coopPess, setCoopPess] = useState();

  const [jarininha, setJarininha] = useState();
  const [lojista, setLojista] = useState();
  const [exColab, setExColab] = useState();

  const [colabsF, setColabF] = useState();
  const [leadersF, setLeadersF] = useState();
  const [clientsF, setClientsF] = useState();
  const [franchiseF, setFranchiseF] = useState();

  const [consumidoresFinaisF, setConsumidoresFinaisF] = useState();
  const [jarinasF, setJarinasF] = useState();

  const [supervisoresF, setSupervisoresF] = useState();
  const [gerentesCaF, setGerentesCaF] = useState();
  const [gerentesAgenciaF, setGerentesAgenciaF] = useState();

  const [coopsAgroF, setCoopsAgroF] = useState();
  const [coopsEmprF, setCoopsEmprF] = useState();
  const [coopsPessF, setCoopsPessF] = useState();

  const [jarininhasF, setJarininhasF] = useState();
  const [lojistasF, setLojistasF] = useState();
  const [exColabsF, setExColabsF] = useState();

  const [type, setType] = useState(types[0]);
  const [camada, setCamada] = useState();

  const [filteredAgent, setFilteredAgent] = useState([]);

  const [currentRequested, setCurrentRequested] = useState();
  const [firstLoaded, setFirstLoaded] = useState();

  useEffect(() => {
    setUserData(getUserData());

    return () => {
      clearFilters();
    }
  }, []);

  useEffect(() => {
    if (!partners.loading) {
      setPartner(partners[0]);
    }
  }, [partners]);

  useEffect(() => {
    if (userData.name) {
      if (!userData.idPartner) {
        getPartners();
      } else {
        setPartner({ value: userData.idPartner })
      }
    }
  }, [userData]);

  useEffect(() => {
    if (!clients.loading && periods.loading) {
      setClient(clients[0]);
    }
  }, [clients]);

  useEffect(() => {
    if (!periods.loading) {
      setPeriod(periods[0]);
      if (!firstLoaded && partner && client) {
        onSearch({
          idParceiro: partner.value,
          idCliente: client.value,
          idPeriodoResposta: periods[0].value,
          idTipoPosicionamento: type.value,
          offset: 0,
          fetch: 100
        })
        setFirstLoaded(true);
      }
    }
  }, [periods]);

  useEffect(() => {
    if (!camadasNegocio.loading) {
      if (currentRequested === 'colabsF') {
        setColabF(camadasNegocio);
      } else if (currentRequested === 'leadersF') {
        setLeadersF(camadasNegocio);
      } else if (currentRequested === 'clientsF') {
        setClientsF(camadasNegocio);
      } else if (currentRequested === 'franchiseF') {
        setFranchiseF(camadasNegocio);
      } else if (currentRequested === 'consumidoresFinaisF') {
        setConsumidoresFinaisF(camadasNegocio);
      } else if (currentRequested === 'jarinasF') {
        setJarinasF(camadasNegocio);
      } else if (currentRequested === 'supervisoresF') {
        setSupervisoresF(camadasNegocio);
      } else if (currentRequested === 'gerentesCaF') {
        setGerentesCaF(camadasNegocio);
      } else if (currentRequested === 'gerentesAgenciaF') {
        setGerentesAgenciaF(camadasNegocio);
      } else if (currentRequested === 'coopsAgroF') {
        setCoopsAgroF(camadasNegocio);
      } else if (currentRequested === 'coopsEmprF') {
        setCoopsEmprF(camadasNegocio);
      } else if (currentRequested === 'coopsPessF') {
        setCoopsPessF(camadasNegocio);
      } else if (currentRequested === 'jarininhasF') {
        setJarininhasF(camadasNegocio);
      } else if (currentRequested === 'lojistasF') {
        setLojistasF(camadasNegocio);
      } else if (currentRequested === 'exColabsF') {
        setExColabsF(camadasNegocio);
      }
    }
  }, [camadasNegocio]);

  useEffect(() => {
    if (!userData.idClient) {
      setClient();
      setPeriod();

      if (partner) {
        getClients({
          idParceiro: partner.value,
        })
      }
    } else {
      setClient({ value: userData.idClient });
    }
  }, [partner]);

  useEffect(() => {
    if (agent) {
      if (agent.value) {
        getCamadas({
          idParceiro: partner.value,
          idCliente: client.value,
          idAgenteHolograma: agent.value,
        })
      } else {
        setFilteredAgent(agent.map(item => item.value));
      }
    } else {
      setFilteredAgent([]);
    }
  }, [agent]);

  useEffect(() => {
    setPeriod();

    if (client) {
      setColabF();
      setLeadersF();
      setClientsF();
      setFranchiseF();

      setConsumidoresFinaisF();
      setJarinasF();

      setSupervisoresF();
      setGerentesCaF();
      setGerentesAgenciaF();    

      setCoopsAgroF();
      setCoopsEmprF();
      setCoopsPessF();

      setJarininhasF();
      setLojistasF();
      setExColabsF();

      setAgent();
      setCamada();
      getPeriods({
        idParceiro: partner.value,
        idCliente: client.value,
      })
      getAgents({
        idParceiro: partner.value,
        idCliente: client.value,
      })
    }
  }, [client]);

  useEffect(() => {
    if (agents && agents.length) {
      setColabF();
      setLeadersF();
      setClientsF();
      setFranchiseF();

      setConsumidoresFinaisF();
      setJarinasF();

      setSupervisoresF();
      setGerentesCaF();
      setGerentesAgenciaF();    

      setCoopsAgroF();
      setCoopsEmprF();
      setCoopsPessF();

      setJarininhasF();
      setLojistasF();
      setExColabsF();

      setAgent();
      setCamada();
      onAgents(agents);

      agents.forEach((item, i) => {
        setTimeout(() => {
          switch (item.value) {
            case '1':
              setCurrentRequested('clientsF');
              getCamadasNegocio({
                idParceiro: partner.value,
                idCliente: client.value,
                idAgenteHolograma: item.value
              })
              break;
              case '2':
                setCurrentRequested('colabsF');
                getCamadasNegocio({
                  idParceiro: partner.value,
                  idCliente: client.value,
                  idAgenteHolograma: item.value
                })
                break;
              case '5':
              setCurrentRequested('leadersF');
              getCamadasNegocio({
                idParceiro: partner.value,
                idCliente: client.value,
                idAgenteHolograma: item.value
              })
              break;
              case '8':
                setCurrentRequested('consumidoresFinaisF');
                getCamadasNegocio({
                  idParceiro: partner.value,
                  idCliente: client.value,
                  idAgenteHolograma: item.value
                })
                break;
              case '9':
              setCurrentRequested('franchiseF');
              getCamadasNegocio({
                idParceiro: partner.value,
                idCliente: client.value,
                idAgenteHolograma: item.value
              })
              break;
              case '10':
                setCurrentRequested('jarinasF');
                getCamadasNegocio({
                  idParceiro: partner.value,
                  idCliente: client.value,
                  idAgenteHolograma: item.value
                })
                break;
                case '11':
                  setCurrentRequested('jarininhasF');
                  getCamadasNegocio({
                    idParceiro: partner.value,
                    idCliente: client.value,
                    idAgenteHolograma: item.value
                  })
                  break;
                  case '12':
                    setCurrentRequested('lojistasF');
                    getCamadasNegocio({
                      idParceiro: partner.value,
                      idCliente: client.value,
                      idAgenteHolograma: item.value
                    })
                    break;
                    case '13':
                      setCurrentRequested('exColabsF');
                      getCamadasNegocio({
                        idParceiro: partner.value,
                        idCliente: client.value,
                        idAgenteHolograma: item.value
                      })
                      break;
                      case '14':
                        setCurrentRequested('coopsAgroF');
                        getCamadasNegocio({
                          idParceiro: partner.value,
                          idCliente: client.value,
                          idAgenteHolograma: item.value
                        })
                        break;
                        case '15':
                          setCurrentRequested('coopsEmprF');
                          getCamadasNegocio({
                            idParceiro: partner.value,
                            idCliente: client.value,
                            idAgenteHolograma: item.value
                          })
                          break;
                          case '16':
                            setCurrentRequested('coopsPessF');
                            getCamadasNegocio({
                              idParceiro: partner.value,
                              idCliente: client.value,
                              idAgenteHolograma: item.value
                            })
                            break;
                            case '17':
                              setCurrentRequested('supervisoresF');
                              getCamadasNegocio({
                                idParceiro: partner.value,
                                idCliente: client.value,
                                idAgenteHolograma: item.value
                              })
                              break;
                              case '18':
                                setCurrentRequested('gerentesCaF');
                                getCamadasNegocio({
                                  idParceiro: partner.value,
                                  idCliente: client.value,
                                  idAgenteHolograma: item.value
                                })
                                break;
                                case '19':
                                  setCurrentRequested('gerentesAgenciaF');
                                  getCamadasNegocio({
                                    idParceiro: partner.value,
                                    idCliente: client.value,
                                    idAgenteHolograma: item.value
                                  })
                                  break;
                                        default:
          }
        }, 1000 * i);
      })
    }
  }, [agents]);

  return (
    <FilterWrapper>
      <FilterTitle>Selecione suas opções de filtro:</FilterTitle>
      {userData.idPartner ? null : (
        <Select
          placeholder="Parceiro"
          value={partner}
          options={partners || []}
          classNamePrefix="select2-selection"
          onChange={(Object) => {
            setPartner(Object);
          }}
        />
      )}
      {userData.idClient ? null : (
        <Select
          placeholder="Cliente"
          value={client}
          options={clients || []}
          classNamePrefix="select2-selection"
          onChange={(Object) => {
            setClient(Object);
          }}
        />
      )}
      <Select
        placeholder="Período"
        value={period}
        options={periods || []}
        classNamePrefix="select2-selection"
        onChange={(Object) => {
          setPeriod(Object);
        }}
      />
      {changeType ? (
        <Select
          placeholder="Tipo"
          value={type}
          options={types || []}
          classNamePrefix="select2-selection"
          onChange={(Object) => {
            setType(Object);
            setFilteredAgent([]);
          }}
        />
      ) : null}

      {changeType && type.value === 'C' ? (
        <>
          <Select
            placeholder="Agente do Holograma"
            value={agent}
            options={agents || []}
            classNamePrefix="select2-selection"
            onChange={(Object) => {
              setAgent(Object);
              setCamada();
            }}
          />
          {camadas.length ? (
            <Select
              placeholder="Camada de negócio"
              value={camada}
              options={camadas || []}
              classNamePrefix="select2-selection"
              onChange={(Object) => {
                setCamada(Object);
              }}
            />
          ) : null}
        </>
      ) : null}

      {!changeType || (changeType && type.value === 'R') ? (
        <>
          <Select
            placeholder="Todas as Relações"
            options={agents || []}
            isMulti
            classNamePrefix="select2-selection"
            onChange={(Object) => {
              setAgent(Object);
            }}
          />

          {onlyAgents ? null : (
            <>
              {(clientsF || colabsF || leadersF || franchiseF) && (
                <hr style={{ borderColor: '#fff', marginBottom: 24 }} />
              )}
              {(clientsF && clientsF.length && (!filteredAgent.length || filteredAgent.includes('1'))) && (
                <Select
                  placeholder="Camadas de negócio - Clientes"
                  options={clientsF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setClien(a);
                  }}
                />
              )}
              {(colabsF && colabsF.length && (!filteredAgent.length || filteredAgent.includes('2'))) && (
                <Select
                  placeholder="Camadas de negócio - Colaboradores"
                  options={colabsF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setColab(a);
                  }}
                />
              )}
              {(leadersF && leadersF.length && (!filteredAgent.length || filteredAgent.includes('5'))) && (
                <Select
                  placeholder="Camadas de negócio - Líderes"
                  options={leadersF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setLeaders(a);
                  }}
                />
              )}
              {(franchiseF && franchiseF.length && (!filteredAgent.length || filteredAgent.includes('9'))) && (
                <Select
                  placeholder="Camadas de negócio - Franquias"
                  options={franchiseF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setFranchise(a);
                  }}
                />
              )}

              {(consumidoresFinaisF && consumidoresFinaisF.length && (!filteredAgent.length || filteredAgent.includes('8'))) && (
                <Select
                  placeholder="Camadas de negócio - Consumidores finais"
                  options={consumidoresFinaisF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setConsumidorFinal(a);
                  }}
                />
              )}

              {(jarinasF && jarinasF.length && (!filteredAgent.length || filteredAgent.includes('10'))) && (
                <Select
                  placeholder="Camadas de negócio - Jarinas"
                  options={jarinasF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setJarina(a);
                  }}
                />
              )}

              {(supervisoresF && supervisoresF.length && (!filteredAgent.length || filteredAgent.includes('17'))) && (
                <Select
                  placeholder="Camadas de negócio - Supervisores"
                  options={supervisoresF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setSupervisor(a);
                  }}
                />
              )}

              {(gerentesCaF && gerentesCaF.length && (!filteredAgent.length || filteredAgent.includes('18'))) && (
                <Select
                  placeholder="Camadas de negócio - Gerentes CA"
                  options={gerentesCaF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setGerenteCa(a);
                  }}
                />
              )}

              {(gerentesAgenciaF && gerentesAgenciaF.length && (!filteredAgent.length || filteredAgent.includes('19'))) && (
                <Select
                  placeholder="Camadas de negócio - Gerentes Agência"
                  options={gerentesAgenciaF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setGerenteAgencia(a);
                  }}
                />
              )}


              {(coopsAgroF && coopsAgroF.length && (!filteredAgent.length || filteredAgent.includes('14'))) && (
                <Select
                  placeholder="Camadas de negócio - Cooperados Gestão Agro"
                  options={coopsAgroF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setCoopAgro(a);
                  }}
                />
              )}

              {(coopsEmprF && coopsEmprF.length && (!filteredAgent.length || filteredAgent.includes('15'))) && (
                <Select
                  placeholder="Camadas de negócio - Cooperados Gestão Empresarial"
                  options={coopsEmprF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setCoopEmpr(a);
                  }}
                />
              )}

              {(coopsPessF && coopsPessF.length && (!filteredAgent.length || filteredAgent.includes('16'))) && (
                <Select
                  placeholder="Camadas de negócio - Cooperados Gestão Pessoal"
                  options={coopsPessF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setCoopPess(a);
                  }}
                />
              )}



              {(jarininhasF && jarininhasF.length && (!filteredAgent.length || filteredAgent.includes('11'))) && (
                <Select
                  placeholder="Camadas de negócio - Jarininhas"
                  options={jarininhasF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setJarininha(a);
                  }}
                />
              )}

              {(lojistasF && lojistasF.length && (!filteredAgent.length || filteredAgent.includes('12'))) && (
                <Select
                  placeholder="Camadas de negócio - Lojistas"
                  options={lojistasF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setLojista(a);
                  }}
                />
              )}

              {(exColabsF && exColabsF.length && (!filteredAgent.length || filteredAgent.includes('13'))) && (
                <Select
                  placeholder="Camadas de negócio - Desembarques"
                  options={exColabsF || []}
                  isMulti
                  classNamePrefix="select2-selection"
                  onChange={(a) => {
                    setExColab(a);
                  }}
                />
              )}

            </>
          )}
        </>
      ) : null}

      <ButtonWrapper>
        <Button
          onClick={(e) => {
            let idsAgentesHolograma = null;
            let idsRelacoesCamadasOpcoes = '';

            if (agent && agent.length) {
              idsAgentesHolograma = agent.map(item => item.value).join('|;') + '|;'
            }

            if (colab && colab.length) {
              const colabOpt = colab.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + colabOpt;
            }

            if (leaders && leaders.length) {
              const leadersOpt = leaders.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + leadersOpt;
            }

            if (clien && clien.length) {
              const clienOpt = clien.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + clienOpt;
            }

            if (franchise && franchise.length) {
              const franchiseOpt = franchise.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + franchiseOpt;
            }

            if (consumidorFinal && consumidorFinal.length) {
              const consumidorFinalOpt = consumidorFinal.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + consumidorFinalOpt;
            }

            if (jarina && jarina.length) {
              const jarinaOpt = jarina.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + jarinaOpt;
            }

            if (supervisor && supervisor.length) {
              const supervisorOpt = supervisor.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + supervisorOpt;
            }

            if (gerenteCa && gerenteCa.length) {
              const gerenteCaOpt = gerenteCa.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + gerenteCaOpt;
            }

            if (gerenteAgencia && gerenteAgencia.length) {
              const gerenteAgenciaOpt = gerenteAgencia.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + gerenteAgenciaOpt;
            }

            if (coopAgro && coopAgro.length) {
              const coopAgroOpt = coopAgro.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + coopAgroOpt;
            }

            if (coopEmpr && coopEmpr.length) {
              const coopEmprOpt = coopEmpr.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + coopEmprOpt;
            }

            if (coopPess && coopPess.length) {
              const coopPessOpt = coopPess.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + coopPessOpt;
            }



            if (jarininha && jarininha.length) {
              const jarininhaOpt = jarininha.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + jarininhaOpt;
            }

            if (lojista && lojista.length) {
              const lojistaOpt = lojista.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + lojistaOpt;
            }

            if (exColab && exColab.length) {
              const exColabOpt = exColab.map(item => item.value).join('|;')
              if (idsRelacoesCamadasOpcoes.length) {
                idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
              }
              idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + exColabOpt;
            }

            let data = {
              idParceiro: partner.value,
              idCliente: client.value,
              idPeriodoResposta: period.value,
              idsAgentesHolograma,
              offset: 0,
              fetch: 100
            }

            if (idsRelacoesCamadasOpcoes.length) {
              data.idsRelacoesCamadasOpcoes = idsRelacoesCamadasOpcoes + '|;';
            };

            let filtroOk = true;

            if (changeType) {
              if (type.value === 'C') {
                if (agent) {
                  data = {
                    idParceiro: partner.value,
                    idCliente: client.value,
                    idPeriodoResposta: period.value,
                    idTipoPosicionamento: type.value,
                    idsRelacoesCamadasOpcoes: (camada?.value) ? camada.value + '|;' : null,
                    nomeRelacaoCamadaOpcao: (camada?.label) ? camada.label : null,
                    idsAgentesHolograma: (agent?.value) ? agent.value + '|;' : null,
                    offset: 0,
                    fetch: 100
                  }
                }
                else {
                  filtroOk = false;
                  alert("Por favor, selecione a relação.");
                }
                onAgents(camadas);
              } else {
                data.idTipoPosicionamento = type.value;
                onAgents(agents);
              }
            }

            if (filtroOk){
              onSearch(data);
            }
          }}
          disabled={false}
        >
          Aplicar filtro
        </Button>
      </ButtonWrapper>
    </FilterWrapper>
  );
}

export default Filters;
