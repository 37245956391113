import React, { useState, useEffect, useContext }  from 'react';
import styled from 'styled-components';
import { getUserData } from 'services/getUserData';
import Select from "react-select";

import { RelativeIndicatorContext } from 'context/RelativeIndicator';

const FilterWrapper = styled.div`
  width: 100%;

  > div {
    margin-bottom: 24px;
  }
`

const FilterTitle = styled.p`
  color: #FFF;
  font-weight: bold;
  margin-bottom: 10px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

const Button = styled.button`
  background: rgba(2,255,103,.4);
  border-radius: 5px;
  color: #0F355F;
  padding: 18px 64px;
  font-weight: bold;
  border: 0;
  width: 100%;

  :hover {
    opacity: .8;
  }
`;

function Filters({ onlyAgents, onSearch }) {
  const {
    partners, getPartners,
    clients, getClients,
    periods, getPeriods,
    agents, getAgents,
    camadas, getCamadas,
    clearFilters,
  } = useContext(RelativeIndicatorContext);

  const [partner, setPartner] = useState();
  const [client, setClient] = useState();
  const [period, setPeriod] = useState();
  const [agent, setAgent] = useState();
  const [camada, setCamada] = useState();
  const [userData, setUserData] = useState({});

  const [firstLoaded, setFirstLoaded] = useState();

  useEffect(() => {
    setUserData(getUserData());
    // getPartners();

    return () => {
      clearFilters();
    }
  }, []);

  useEffect(() => {
    if (!partners.loading) {
      setPartner(partners[0]);
    }
  }, [partners]);

  useEffect(() => {
    if (userData.name) {
      if (!userData.idPartner) {
        getPartners();
      } else {
        setPartner({ value: userData.idPartner })
      }
    }
  }, [userData]);

  useEffect(() => {
    if (!clients.loading && periods.loading) {
      setClient(clients[0]);
    }
  }, [clients]);

  useEffect(() => {
    if (!periods.loading && !agents.loading) {
      setPeriod(periods[0]);
      setAgent(agents[0]);
      if (!firstLoaded && partner && client) {
        let data = {
          idParceiro: partner.value,
          idCliente: client.value,
          idPeriodoResposta: periods[0]?.value,
        };

        if (onlyAgents) {
          data.idsAgentesHolograma = (agents[0].value) ? agents[0].value.toString() + '|;' : null;

          onSearch(data)
          setFirstLoaded(true);
        }
      }
    }
  }, [periods, agents]);

  useEffect(() => {
    if (!userData.idClient) {
      setClient();
      setPeriod();

      if (partner) {
        getClients({
          idParceiro: partner.value,
        })
      }
    } else {
      setClient({ value: userData.idClient });
    }
  }, [partner]);

  useEffect(() => {
    setPeriod();

    if (client) {
      getPeriods({
        idParceiro: partner.value,
        idCliente: client.value,
      })
      getAgents({
        idParceiro: partner.value,
        idCliente: client.value,
      })
    }
  }, [client]);

  useEffect(() => {
    if (agent) {
      getCamadas({
        idParceiro: partner.value,
        idCliente: client.value,
        idAgenteHolograma: agent.value,
      })
    }
  }, [agent]);

  useEffect(() => {
    if (camadas && !onlyAgents && !camadas.loading && camadas.length) {
      setCamada(camadas[0]);

      if (!firstLoaded) {
        let data = {
          idParceiro: partner.value,
          idCliente: client.value,
          idPeriodoResposta: period?.value,
          idAgenteHolograma: agent?.value,
          idCamada: camadas[0].value + '|;',
        };

        onSearch(data, camadas);
        setFirstLoaded(true);
      }
    }
  }, [camadas]);

  return (
    <FilterWrapper>
      <FilterTitle>Selecione suas opções de filtro:</FilterTitle>
      {userData.idPartner ? null : (
        <Select
          placeholder="Parceiro"
          value={partner}
          options={partners || []}
          classNamePrefix="select2-selection"
          onChange={(Object) => {
            setPartner(Object);
          }}
        />
      )}
      {userData.idClient ? null : (
        <Select
          placeholder="Cliente"
          value={client}
          options={clients || []}
          classNamePrefix="select2-selection"
          onChange={(Object) => {
            setClient(Object);
          }}
        />
      )}
      <Select
        placeholder="Período"
        value={period}
        options={periods || []}
        classNamePrefix="select2-selection"
        // defaultValue={parceiro || []}
        onChange={(Object) => {
          setPeriod(Object);
        }}
      />
      <Select
        placeholder="Agente do Holograma"
        value={agent}
        options={agents || []}
        classNamePrefix="select2-selection"
        // defaultValue={parceiro || []}
        onChange={(Object) => {
          setAgent(Object);
        }}
      />
      {!onlyAgents && (
        <Select
          placeholder="Camada de negócio"
          value={camada}
          options={camadas || []}
          classNamePrefix="select2-selection"
          // defaultValue={parceiro || []}
          onChange={(Object) => {
            setCamada(Object);
          }}
        />
      )}
      <ButtonWrapper>
        <Button
          onClick={(e) => {
            let data = {
              idParceiro: partner.value,
              idCliente: client.value,
              idPeriodoResposta: period?.value,
              idCamada: (camada?.value) ? camada.value + '|;' : null,
            };

            if (onlyAgents) {
              data.idsAgentesHolograma = (agent?.value) ? agent.value.toString() + '|;' : null;
            } else {
              data.idAgenteHolograma = agent?.value;
            }

            onSearch(data, camadas)
          }}
          disabled={false}
        >
          Aplicar filtro
        </Button>
      </ButtonWrapper>
    </FilterWrapper>
  );
}

export default Filters;
