import axios from 'axios';
import queryString from 'query-string';
import { getData } from 'utils/token';
import { apiRoute } from 'env';

export const postICRRelacoes = async (payload) => {
  const data = getData();

  const query = queryString.stringify(payload);

  try {
    const response = await axios({
      url: `${apiRoute}/pesquisas/retornarICRRelacoesCamadas?${query}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    if (!response.data || !response.data.resultadoObjeto) {
      return {
        success: false,
        error: true,
      };
    }
    return {
      success: true,
      data: response.data.resultadoObjeto
    };
  } catch (e) {
    let message = 'Infelizmente não foi possível pesquisar. Verifique sua internet e tente novamente.';

    return {
      success: false,
      error: true,
      message
    };
  }
};

export const postICRRelacoesCamadas = async (payload) => {
  const data = getData();
  const query = queryString.stringify(payload);

  try {
    const response = await axios({
      url: `${apiRoute}/pesquisas/retornarICRRelacoesCamadas?${query}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    if (!response.data || !response.data.resultadoObjeto) {
      return {
        success: false,
        error: true,
      };
    }
    return {
      success: true,
      data: response.data.resultadoObjeto
    };
  } catch (e) {
    let message = 'Infelizmente não foi possível pesquisar. Verifique sua internet e tente novamente.';

    return {
      success: false,
      error: true,
      message
    };
  }
}

export const postProminientSupplication = async (payload) => {
  const data = getData();
  const query = queryString.stringify(payload);

  try {
    const response = await axios({
      url: `${apiRoute}/pesquisas/retornarICRVisaoSuplicas?${query}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    if (!response.data || !response.data.resultadoObjeto) {
      return {
        success: false,
        error: true,
      };
    }
    return {
      success: true,
      data: response.data.resultadoObjeto
    };
  } catch (e) {
    let message = 'Infelizmente não foi possível pesquisar. Verifique sua internet e tente novamente.';

    return {
      success: false,
      error: true,
      message
    };
  }
}

export const postCloudData = async (payload) => {
  const data = getData();
  const query = queryString.stringify(payload);

  try {
    const response = await axios({
      url: `${apiRoute}/pesquisas/retornarDadosNuvem?${query}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    if (!response.data || !response.data.resultadoObjeto) {
      return {
        success: false,
        error: true,
      };
    }
    return {
      success: true,
      data: response.data.resultadoObjeto
    };
  } catch (e) {
    let message = 'Infelizmente não foi possível pesquisar. Verifique sua internet e tente novamente.';

    return {
      success: false,
      error: true,
      message
    };
  }
};

export const postRespondentList = async (payload) => {
  const data = getData();
  const query = queryString.stringify(payload);

  try {
    const response = await axios({
      url: `${apiRoute}/pesquisas/retornarListaRespondentes?${query}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    if (!response.data || !response.data.resultadoObjeto) {
      return {
        success: false,
        error: true,
      };
    }
    return {
      success: true,
      data: response.data.resultadoObjeto
    };
  } catch (e) {
    let message = 'Infelizmente não foi possível pesquisar. Verifique sua internet e tente novamente.';

    return {
      success: false,
      error: true,
      message
    };
  }
};

export const postPosicionamentoRelacional = async (payload) => {
  const data = getData();
  const query = queryString.stringify(payload);

  try {
    const response = await axios({
      url: `${apiRoute}/pesquisas/retornarRespondentesPosicionamentoRelacional?${query}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    if (!response.data || !response.data.resultadoObjeto) {
      return {
        success: false,
        error: true,
      };
    }
    return {
      success: true,
      data: response.data.resultadoObjeto
    };
  } catch (e) {
    let message = 'Infelizmente não foi possível pesquisar. Verifique sua internet e tente novamente.';

    return {
      success: false,
      error: true,
      message
    };
  }
};

export const postPainelSuplicas = async (payload) => {
  const data = getData();
  const query = queryString.stringify(payload);

  try {
    const response = await axios({
      url: `${apiRoute}/pesquisas/listarPainelSuplicas?${query}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    if (!response.data || !response.data.resultadoObjeto || !response.data.resultadoObjeto.data) {
      return {
        success: false,
        error: true,
      };
    }
    return {
      success: true,
      data: response.data.resultadoObjeto.data,
      header: response.data.resultadoObjeto.header
    };
  } catch (e) {
    let message = 'Infelizmente não foi possível pesquisar. Verifique sua internet e tente novamente.';

    return {
      success: false,
      error: true,
      message
    };
  }
};

export const postFrasesPalavrasRespondente = async (payload) => {
  const data = getData();
  const query = queryString.stringify(payload);

  try {
    const response = await axios({
      url: `${apiRoute}/pesquisas/retornarFrasesPalavrasRespondente?${query}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    if (!response.data || !response.data.resultadoObjeto) {
      return {
        success: false,
        error: true,
      };
    }
    return {
      success: true,
      data: response.data.resultadoObjeto
    };
  } catch (e) {
    let message = 'Infelizmente não foi possível pesquisar. Verifique sua internet e tente novamente.';

    return {
      success: false,
      error: true,
      message
    };
  }
};

export const postICREvolucao = async (payload) => {
  const data = getData();
  const query = queryString.stringify(payload);

  try {
    const response = await axios({
      url: `${apiRoute}/pesquisas/retornarICREvolucaoRelacoes?${query}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    if (!response.data || !response.data.resultadoObjeto) {
      return {
        success: false,
        error: true,
      };
    }
    return {
      success: true,
      data: response.data.resultadoObjeto
    };
  } catch (e) {
    let message = 'Infelizmente não foi possível pesquisar. Verifique sua internet e tente novamente.';

    return {
      success: false,
      error: true,
      message
    };
  }
};

export const postICREvolucaoSuplicas = async (payload) => {
  const data = getData();
  const query = queryString.stringify(payload);

  try {
    const response = await axios({
      url: `${apiRoute}/pesquisas/retornarICREvolucaoSuplicas?${query}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    if (!response.data || !response.data.resultadoObjeto) {
      return {
        success: false,
        error: true,
      };
    }
    return {
      success: true,
      data: response.data.resultadoObjeto
    };
  } catch (e) {
    let message = 'Infelizmente não foi possível pesquisar. Verifique sua internet e tente novamente.';

    return {
      success: false,
      error: true,
      message
    };
  }
};

export const postDadosAgora = async (payload) => {
  const data = getData();
  const query = queryString.stringify(payload);

  try {
    const response = await axios({
      url: `${apiRoute}/pesquisas/retornarDadosAgora?${query}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    if (!response.data || !response.data.resultadoObjeto) {
      return {
        success: false,
        error: true,
      };
    }
    return {
      success: true,
      data: response.data.resultadoObjeto
    };
  } catch (e) {
    let message = 'Infelizmente não foi possível pesquisar. Verifique sua internet e tente novamente.';

    return {
      success: false,
      error: true,
      message
    };
  }
};
