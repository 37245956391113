import React, { createContext, useState } from 'react';
import { getUserData } from 'services/filters';
import { setData } from 'utils/token';
import 'cross-fetch/polyfill';
import { poolData } from 'env';

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [loginContent, setLogin] = useState({});

  const submitLogin = async (payload) => {
    setLogin({ loading: true });

    const authenticationData = {
      Username: payload.email,
      Password: payload.password,
    };

    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
      Username: authenticationData.Username,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        newPasswordRequired: (result) => {
          resolve({
            success: true,
            action: 'changePassword',
          });

          setLogin({ loading: false });
        },
        onSuccess: async (session) => {
          setData({
            accessToken: session.getAccessToken(),
            refreshToken: session.getRefreshToken(),
            idToken: session.getIdToken(),
            jwt: session.getIdToken().getJwtToken(),
          });

          const userData = await getUserData();
          const response = userData?.data && userData?.data[0];

          // cognitoUser.getSession();

          if (userData.success && response) {
            cognitoUser.updateAttributes([
              {
                Name: 'custom:name',
                Value: response?.cad_nome_completo,
              },
              {
                Name: 'custom:idPartner',
                Value: response?.icr_id_parceiro,
              },
              {
                Name: 'custom:idClient',
                Value: response?.icr_id_cliente,
              },
              {
                Name: 'custom:nameClient',
                Value: response?.icr_nome_cliente,
              },
              {
                Name: 'custom:idLicense',
                Value: response?.icr_id_licenca,
              },
              {
                Name: 'custom:nameLicense',
                Value: response?.icr_nome_licenca,
              },
              {
                Name: 'custom:bloquear',
                Value: response?.icr_bloquear,
              },
              {
                Name: 'custom:msgBloquear',
                Value: response?.msg_bloquear,
              }
            ], function(err, result) {
              resolve({
                success: true,
                action: false,
              });

              setLogin({ loading: false });
            });
          } else {
            resolve({
              success: true,
              action: false,
            });

            setLogin({ loading: false });
          }
        },
        onFailure: (err) => {
          resolve({
            success: false,
            action: false,
            message: (() => {
              if (err.code === 'NotAuthorizedException') {
                return 'Usuário ou senhas inválidos!';
              }

              return 'Erro inesperado do servidor!';
            })()
          });

          setLogin({ loading: false });
        },
      });
    });
  };

  const changePassword = async (payload) => {
    setLogin({ loading: true });

    const authenticationData = {
      Username: payload.email,
      Password: payload.oldPassword,
    };

    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
        Username: payload.email,
        Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        newPasswordRequired: function (result) {
          delete result.email_verified;
          delete result.phone_number_verified;
          result.name = userData.Username;

          cognitoUser.completeNewPasswordChallenge(payload.newPassword, result, {
            onSuccess: async (session) => {
              setData({
                accessToken: session.getAccessToken(),
                refreshToken: session.getRefreshToken(),
                idToken: session.getIdToken(),
                jwt: session.getIdToken().getJwtToken(),
              });

              const userData = await getUserData();
              const response = userData?.data && userData?.data[0];

              // cognitoUser.getSession();

              if (userData.success && response) {
                cognitoUser.updateAttributes([
                  {
                    Name: 'custom:name',
                    Value: response?.cad_nome_completo,
                  },
                  {
                    Name: 'custom:idPartner',
                    Value: response?.icr_id_parceiro,
                  },
                  {
                    Name: 'custom:idClient',
                    Value: response?.icr_id_cliente,
                  },
                  {
                    Name: 'custom:nameClient',
                    Value: response?.icr_nome_cliente,
                  },
                  {
                    Name: 'custom:idLicense',
                    Value: response?.icr_id_licenca,
                  },
                  {
                    Name: 'custom:nameLicense',
                    Value: response?.icr_nome_licenca,
                  },
                  {
                    Name: 'custom:bloquear',
                    Value: response?.icr_bloquear,
                  },
                  {
                    Name: 'custom:msgBloquear',
                    Value: response?.msg_bloquear,
                  }
                ], function(err, result) {
                  resolve({
                    success: true,
                    action: false,
                  });

                  setLogin({ loading: false });
                });
              } else {
                resolve({
                  success: true,
                  action: false,
                });

                setLogin({ loading: false });
              }
            },
            onFailure: function (err) {
              resolve({
                success: false,
                action: false,
                message: (() => {
                  if (err.code === 'NotAuthorizedException') {
                    return 'Usuário ou senhas inválidos!';
                  }

                  return 'Erro inesperado do servidor!';
                })()
              });

              setLogin({ loading: false });
            },
          });
        },
        onFailure: function (err) {
          resolve({
            success: false,
            action: false,
            message: (() => {
              if (err.code === 'NotAuthorizedException') {
                return 'Usuário ou senhas inválidos!';
              }

              return 'Erro inesperado do servidor!';
            })()
          });

          setLogin({ loading: false });
        },
      })
    });
  };

  const submitResetPassword = async (payload) => {
    setLogin({ loading: true });

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
      Username: payload.email,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (result) => {
          resolve({
            success: true,
            action: false,
          });

          setLogin({ loading: false });
        },
        onFailure: (err) => {
          resolve({
            success: false,
            action: false,
            message: (() => {
              if (err.code === 'NotAuthorizedException') {
                return 'Usuário não encontrado!';
              }

              return 'Erro inesperado do servidor!';
            })()
          });

          setLogin({ loading: false });
        },
      });
    });
  };

  const submitVerify = async (payload) => {
    setLogin({ loading: true });

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
      Username: payload.email,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(payload.verificationCode, payload.newPassword, {
        onSuccess: (result) => {
          resolve({
            success: true,
            action: false,
          });

          setLogin({ loading: false });
        },
        onFailure: (err) => {
          resolve({
            success: false,
            action: false,
            message: (() => {
              if (err.code === 'CodeMismatchException' || err.code === 'NotAuthorizedException') {
                return 'Código inválido!';
              } else if (err.code === 'ExpiredCodeException') {
                return 'Código expirado!';
              }

              return 'Erro inesperado do servidor!';
            })()
          });

          setLogin({ loading: false });
        },
      });
    });
  };

  const clearAuthContext = () => {
    setLogin({});
  };

  return (
    <AuthContext.Provider value={{
      submitLogin, loginContent,
      submitResetPassword,
      submitVerify,
      changePassword,
      clearAuthContext,
    }}>
      {children}
    </AuthContext.Provider>
  )
}
