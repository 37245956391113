import styled from 'styled-components'

export const AuthContainer = styled.div`
  background: transparent;
  display: flex;
  // height: 100vh;
  min-height: 620px;

  @media (max-width: 1024px) {
    display: block;
    height: auto;
  }
`
export const AuthFormContainer = styled.div`
  flex: 1;
`
export const AuthFormContent = styled.div`
  width: 460px;
  margin: 20px auto 20px;
  color: #4E4D4C;

  @media (max-width: 520px) {
    width: 90vw;
    height: auto;
  }
`
export const AuthFormTitle = styled.h1`
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 32px;
  font-family: "Exo 2", sans-serif;
  color: #1A1A1A;
`
export const AuthFormText = styled.p`
  font-size: ${props => props.size ? props.size : '16'}px;
  line-height: 28px;
  margin-bottom: 32px;
  color: ${props => props.color ? props.color : 'inherit'};
`
export const AuthImageContainer = styled.div`
  background: #0F355F;
  flex: 1;

  @media (max-width: 1024px) {
    display: none;
  }
`

export const ImageContainer = styled.div`
  display: table-cell;
  height: 100vh;
  min-height: 620px;
  text-align: center;
  width: 50vw;
  vertical-align: middle;
`
export const MainImage = styled.img`
  filter: grayscale(100%);
  width: 460px;
  border-radius: 5px;
`
export const IconsRelative = styled.div`
  position: relative;
`
export const Icon = styled.img`
  position: absolute;
`
export const DefaultLink = styled.div`
  margin-top: 32px;

  a {
    color: #1A1A1A;
    text-decoration: none;
  }
`;

export const PasswordValidations = styled.div`
  display: flex;
  color: #898989;
  margin-bottom: 30px;
  font-size: 14px;
  flex-wrap: wrap;
  transition: all .25s ease-in-out 0s;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    margin-bottom: 5px;

    span {
      padding-left: 5px;
      padding-top: 2px;

      &.checked {
        transition: all .25s ease-in-out 0s;
        color: #0FA958;
      }
    }
  }
`;

export const ButtonsBox = styled.div`
  span {
    margin: 0 16px;
  }

  .buttonGoogle {
    border-radius: 25px !important;
    height: 50px;
    overflow: auto;

    > div {
      margin: 0;
      margin-left: 14px;
    }
  }
`;

export const EmojiBox = styled.div`
  display: flex;
  background: #F6F6F4;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  border-radius: 4px;

  p {
    margin: 0;
    margin-left: 16px;
    font-size: 14px;
    line-height: 24px;
  }
`;
