import React from "react";
import styled from 'styled-components';
import Email from 'assets/capital/email.svg';
import Whats from 'assets/capital/whats.svg';

import { Button } from '../../page/Home/atoms';

export const Contact = styled.div`
  background: #089CA3;
  padding-bottom: 30px;

  > div {
    max-width: 1240px;
    margin: 0 auto;
    padding: 16px 0;

    h2 {
      color: #FFF;
      text-align: center;
      font-size: 68px;
      margin-bottom: 40px;
    }
  }
`

export const ContactInfo = styled.div`
  color: #FFF;
  font-size: 28px;

  @media(min-width: 900px) {
    padding-right: 25px;
    max-width: 50%;
  }

  @media(max-width: 899px) {
    margin-bottom: 40px;
    font-size: 22px;

    img {
      max-width: 45px;
    }
  }

  .warning-msg {
    color: #FACD1B;
    font-weight: bold;
  }

  .whats-block {
    margin-bottom: 30px;
  }

  .whats-text {
    font-size: 44px;
    font-weight: bold;
    position: relative;
    top: 8px;

    @media(max-width: 899px) {
      font-size: 36px;
    }
  }

  img {
    margin-right: 15px;
  }

  hr {
    background: #FFF;
    margin: 2rem 0;
  }
`

export const ContactFlex = styled.div`
  display: flex;

  @media(max-width: 940px) {
    flex-flow: column nowrap;
    margin: 0 20px;
  }
`

export const ContactForm = styled.form`
  background: #0F355F;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-width: 100%;

  input, textarea {
    padding: 14px 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .checkbox {
    margin-right: 10px;
  }

  label {
    color: #FFF;
    font-size: 14px;
  }

  @media(min-width: 900px) {
    min-width: 500px;
  }
`

function ContactComponent() {
  return (
    <Contact>
      <div>
        <h2>Vamos conversar?</h2>
        <ContactFlex>
          <ContactInfo>
            <p>Desenvolva a relação que os seus clientes, líderes e colaboradores merecem.</p>
            <p className="warning-msg">Peça agora sua demonstração.</p>
            <hr />
            <p>Se preferir, ligue ou nos chame no WhatsApp:</p>
            <div className="whats-block">
              <img src={Whats} alt="Capital Relacional" />
              <span className="whats-text">(11) 97595-3382</span>
            </div>
            <p>Ou no email:</p>
            <div>
              <img src={Email} alt="Capital Relacional" />
              <span>contato@capitalrelacional.com.br</span>
            </div>
          </ContactInfo>
          <div>
            <ContactForm>
              <input type="text" placeholder="Nome completo" />
              <input type="email" placeholder="Email" />
              <input type="tel" placeholder="Telefone / WhatsApp" />
              <input type="text" placeholder="Empresa" />
              <textarea name="" id="" cols="20" rows="5" placeholder="Mensagem (opcional)"></textarea>
              <div>
                <input className="checkbox" type="checkbox" id="privacy" name="privacy" />
                <label htmlFor="privacy">Li e concordo com a política de privacidade</label>
              </div>
              <div className="checkbox-wrapper">
                <input className="checkbox" type="checkbox" id="comunication" name="comunication" />
                <label htmlFor="comunication">Concordo em receber comunicações</label>
              </div>
              <Button full>QUERO UMA DEMONSTRAÇÃO</Button>
            </ContactForm>
          </div>
        </ContactFlex>
      </div>
    </Contact>
  );
};

export default ContactComponent;
