import React, { useRef, useEffect } from 'react';
import { BryntumGrid } from '@bryntum/grid-react';
import { StringHelper } from "@bryntum/grid/grid.umd";
import { Store, GridRowModel} from '@bryntum/grid/grid.umd.js';
import { retornarGraficoCapitalRelacional, retornarGraficoCapitalRelacionalDinamico } from "../../utils/utils-amcharts";

const suplicas = [
  "suplica_1_",
  "suplica_2_",
  "suplica_3_",
  "suplica_4_",
  "suplica_5_",
  "suplica_6_",
  "suplica_7_",
  "suplica_8_"
];

const engajamentos = [
  { titulo: "Convidados:", propriedade: "pesquisas_enviadas" },
  { titulo: "Respondentes:", propriedade: "pesquisas_feitas" },
  { titulo: "Em andamento:", propriedade: "pesquisas_andamento" },
  { titulo: "Pendentes:", propriedade: "pesquisas_nao_respondidas" }
]

const larguraSuplica = 130;
//Largura da coluna da visualização no computador
const larguraColunaComputador = 300;
//Largura de todas as colunas
class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      descricao: 0,
    });
    this.set({
      descricao: linha.descricao,
    });
  }
}

const formatarHeader = (valor) => {
  if (valor == 'Conquiste a minha confiança!')
    return 'CONQUISTE<br />A MINHA<br />CONFIANÇA!';

  return valor.replace(' ', '<br />');
}

const Type = ({ dados }) => {
  const grid = useRef(null);
  const config = {
    responsiveLevels: {
      small: 576,
      medium: 868,
      large: '*',
    },
    readOnly: true,
    features : {
      cellTooltip : {
        hoverDelay      : 100,
        textContent     : true,
        tooltipRenderer : ({ record, column }) => StringHelper.encodeHtml(record[column.field])
      }
    },
    store: new Store({
      visualizacao: "relacao",
      tree: false,
      data: dados.data
    }),
    columns: [
      {
        text: dados.header.icr_descricao,
        field: 'icr_descricao',
        flex: 1,
        filterable: false,
        minWidth: 120,
        minHeight: 100,
        autoHeight: true,
        tooltipRenderer: false,
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: 'ICR',
        field: 'relacao_icr',
        width: 100,
        align: "center",
        htmlEncode: false,
        filterable: false,
        autoHeight: true,
        tooltipRenderer: false,
        renderer({ value, cellElement, record }) {
          cellElement.classList.add(`p-0`)
          return value ? `
            <div class="w-100">
              <div class="w-100 text-center font-poppins font-weight-bolder p-0 text-white bg-${record.relacao_cor}" style="font-size: ${value.includes('.') || value.includes('+') ? "30px" : "45px"};line-height:70px;">${value}</div>
            </div>` : '';
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: 'Engajamento',
        field: 'pesquisas_feitas',
        width: 110,
        autoHeight: true,
        align: "center",
        htmlEncode: false,
        filterable: false,
        tooltipRenderer: ({ record }) => {
          return `
            <div>      
              <div class="d-flex"><span class="flex-grow-1 pr2">${engajamentos[0].titulo}</span> <b>${(record[engajamentos[0].propriedade]).toLocaleString('pt-BR')}</b></div>
              <div class="d-flex"><span class="flex-grow-1 pr2">${engajamentos[1].titulo}</span> <b>${(record[engajamentos[1].propriedade]).toLocaleString('pt-BR')}</b></div>
              <div class="d-flex"><span class="flex-grow-1 pr2">${engajamentos[2].titulo}</span> <b>${(record[engajamentos[2].propriedade]).toLocaleString('pt-BR')}</b></div>
              <div class="d-flex"><span class="flex-grow-1 pr2">${engajamentos[3].titulo}</span> <b>${(record[engajamentos[3].propriedade]).toLocaleString('pt-BR')}</b></div>
            </div>
          `;
        }, 
        renderer({ value, cellElement, record }) {
          const cor =
            record.pesquisas_feitas_indicador === "vermelho"
              ? "text-danger"
              : record.pesquisas_feitas_indicador === "verde"
                ? "text-success"
                : "text-warning";

          return (
            <div>
              <div className={`w-100 text-center font-weight-bolder p-0 ${cor}`}>
                <i className={`fas fa-circle ${cor}`}></i>
              </div>
            </div>
          );
          // `
          // <div>
          //   <div class="w-100 text-center font-weight-bolder p-0 ${cor}" style="font-size:30px;height:40px;" data-tip='Teste 123' data-for='eng_${record._internalId}'>
          //     <i class="fas fa-circle ${cor}"></i>
          //   </div>
          //   <div class="w-100 text-center">${record.pesquisas_feitas}</div>
          //   <div class="w-100 text-center" style="font-size:10px;margin-top: -7px;opacity: 0.6;">${record.pesquisas_feitas > 1 ? "repondentes" : "respondente"}</div>
          //   <ReactTooltip id='eng_${record._internalId}' />
          // </div>`;
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.header.suplica_1_nome,
        field: 'suplica_1_resultado',
        width: larguraSuplica,
        align: "center",
        autoHeight: true,
        htmlEncode: false,
        filterable: false,
        tooltipRenderer: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.header.suplica_2_nome,
        field: 'suplica_2_resultado',
        width: larguraSuplica,
        align: "center",
        autoHeight: true,
        htmlEncode: false,
        filterable: false,
        tooltipRenderer: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.header.suplica_3_nome,
        field: 'suplica_3_resultado',
        width: larguraSuplica,
        align: "center",
        autoHeight: true,
        htmlEncode: false,
        filterable: false,
        tooltipRenderer: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.header.suplica_4_nome,
        field: 'suplica_4_resultado',
        width: larguraSuplica,
        align: "center",
        autoHeight: true,
        htmlEncode: false,
        filterable: false,
        tooltipRenderer: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.header.suplica_5_nome,
        field: 'suplica_5_resultado',
        width: larguraSuplica,
        align: "center",
        autoHeight: true,
        htmlEncode: false,
        filterable: false,
        tooltipRenderer: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.header.suplica_6_nome,
        field: 'suplica_6_resultado',
        width: larguraSuplica,
        align: "center",
        autoHeight: true,
        htmlEncode: false,
        filterable: false,
        tooltipRenderer: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.header.suplica_7_nome,
        field: 'suplica_7_resultado',
        width: larguraSuplica,
        align: "center",
        autoHeight: true,
        htmlEncode: false,
        filterable: false,
        tooltipRenderer: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.header.suplica_8_nome,
        field: 'suplica_8_resultado',
        width: larguraSuplica,
        align: "center",
        autoHeight: true,
        htmlEncode: false,
        filterable: false,
        tooltipRenderer: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      }
    ],
  };

  useEffect(() => {
    if (!config?.store._data) return;

    config.store._data.map((item, i) => {
      suplicas.map((suplica, i_suplica) => {
        setTimeout(() => {
          retornarGraficoCapitalRelacionalDinamico(item[`${suplica}resultado`], `painelGraficoSuplica_${i}_${i_suplica}`, { id: `graficoSuplica_${i}_${i_suplica}` });
        }, 1000);
      })
    })

    grid.current.instance.on('cellMouseOver', (event) => {
      document.querySelectorAll(`[data-column-id="${event.column.id}"]`).forEach((item, indice) => {
        if (indice)
          item.style.setProperty("background-color", "rgba(15,53,95,0.05)", "important");
  
      });
    });
  
  
    grid.current.instance.on('cellMouseOut', (event) => {
      document.querySelectorAll(`[data-column-id="${event.column.id}"]`).forEach((item, indice) => {
        if (indice)
          item.style.removeProperty("background-color");
      });
    });

  }, [config, config.store._data]);

  return (
    <div>
      <BryntumGrid
        ref={grid}
        {...config}
        treeFeature={false}
        columnReorderFeature={false}
        cellEditFeature={false}
        summaryFeature={false}
        sortFeature={false}
        filterBarFeature={{ compactMode: true }}
        cellMenuFeature={{
          disabled: true
        }}
        headerMenuFeature={{
          items: {
            hideColumn: false
          }
        }}
      />
    </div>
  );
};

export default React.memo(Type);
