import React from 'react';
import styled from 'styled-components';
import Loader from 'components/Loader';

const LoadingStyled = styled.div`
  background: rgba(255,255,255,0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  opacity: 1;
  transition: visibility 0s 1.5s, z-index 0s 1.5s, opacity 1.5s ease-out;

  .loader {
    opacity: 1;
    transition: opacity .2s ease-out;
  }

  &.show {
    visibility: visible;
    opacity: 1;
    transition: visibility .1s, z-index 0s .1s, opacity .5s ease-in;

    .loader {
      opacity: 1;
    }
  }

  &.hide {
    visibility: hidden;
    opacity: 0;
    z-index: -5;

    .loader {
      opacity: 0;
    }
  }
`;

function Loading({
  show,
  size,
}) {
  return (
    <LoadingStyled className={show ? 'show' : 'hide'}>
      <Loader size={size} />
    </LoadingStyled>
  )
};

Loading.defaultProps = {
  show: false,
};

export default Loading;
