import React from "react";
import styled from 'styled-components';
import Logo from 'assets/capital-relacional.png';
import Instagram from 'assets/capital/instagram.svg';
import Linkedin from 'assets/capital/linkedin.svg';
import Youtube from 'assets/capital/youtube.svg';

export const Footer = styled.div`
  background: #0F355F;

  .logo {
    max-width: 150px;
  }

  > div {
    max-width: 1240px;
    margin: 0 auto;
    padding: 16px 0;
    background: #0F355F;
    display: flex;
    align-items: center;

    .first-block {
      @media(min-width: 800px) {
        display: flex;
      }
    }

    @media(max-width: 800px) {
      justify-content: space-between;
    }
  }

  @media(max-width: 800px) {
    padding: 0 10px;
  }
`

export const FooterSocial = styled.div`
  margin: 0 70px;

  a {
    margin-right: 10px;
  }

  @media(max-width: 800px) {
    margin: 15px 0;

    a {
      margin-bottom: 10px;

      img {
        max-width: 26px;
      }
    }
  }
`

export const LinkList = styled.div`
  display: grid;

  a {
    font-size: 14px;
    color: #FFF;
  }

  @media(max-width: 800px) {
    justify-items: end;
    margin-top: -20px;
  }

  @media(min-width: 800px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    grid-template-rows: 20px 20px 20px;
    grid-auto-columns: 200px;
    grid-auto-flow: column;
    grid-gap: 5px;
  }
`

function FooterComponent() {
  return (
    <Footer>
      <div>
        <div className="first-block">
          <a href="/">
            <img className="logo" src={Logo} alt="Capital Relacional" />
          </a>
          <FooterSocial>
            <a href="https://www.instagram.com/capitalrelacional" target="_blank">
            <img src={Instagram} alt="Capital Relacional" />
            </a>
            <a href="https://www.instagram.com/capitalrelacional" target="_blank">
            <img src={Linkedin} alt="Capital Relacional" />
            </a>
            <a href="https://youtube.com/playlist?list=PLFF7eD2FaPLcE6KQDqgmI6Ttkce7cfkLk" target="_blank">
            <img src={Youtube} alt="Capital Relacional" />
            </a>
          </FooterSocial>
        </div>
        <LinkList>
          <a href="">Plataforma</a>
          <a href="">Onboard</a>
          <a href="/#/planos">Planos</a>
          <a href="/#/sobre-nos">Sobre nós ...</a>
        </LinkList>
      </div>
    </Footer>
  );
};

export default FooterComponent;
