import 'cross-fetch/polyfill';
import { getData, setData, deleteData } from 'utils/token';
import { poolData } from 'env';

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
let cognitoUser = userPool.getCurrentUser();

export const getRefreshToken = () => {
  if (!cognitoUser) {
    cognitoUser = userPool.getCurrentUser();
  }

  const data = getData();

  if (!data?.refreshToken?.token) {
    return;
  }

  if (!cognitoUser) {
    return;
  }

  cognitoUser.refreshSessionIfPossible();
}

export const clearUserData = () => {
  cognitoUser.clearCachedUserData();
  deleteData();
  cognitoUser.signOut(() => console.log('SignOut'));
};

export const getUserData = () => {
  cognitoUser = userPool.getCurrentUser();

  const data = JSON.parse(cognitoUser.getUserDataFromCache());

  let newUserData = {
    //removidos
    hasRelativeIndicators: false,
    hasBussinessLayers: false,
    hasRelationalCompetence: false,
    //ativos
    hasCultureIndicators: false,
    hasProminentSupplication: false,
    hasSupplicationDashboard: false,
    hasOportunityCloudPage: false,
    hasRelationalPositionPage: false,
    hasRelationalHistoricPage: false
  };

  data?.UserAttributes?.filter(item => item.Name.indexOf('custom:') > -1)
                       .forEach((item) => newUserData[(item.Name).replace('custom:', '')] = item.Value);

  newUserData.plan = ((newUserData.nameLicense || 'Admin').replace('Plano ', '')).toString();

  if (newUserData?.bloquear == "1") {
    newUserData.planClass = 'other';
  }
  else {
    newUserData.planClass = newUserData.plan;

    if (newUserData.idLicense === "10") {
      newUserData.hasOportunityCloudPage = true;
      newUserData.hasProminentSupplication = true;
      newUserData.hasCultureIndicators = true;
      newUserData.hasRelationalHistoricPage = true;
    } else  if (newUserData.idLicense === "20") {
      newUserData.hasOportunityCloudPage = true;
      newUserData.hasProminentSupplication = true;
      newUserData.hasCultureIndicators = true;
      newUserData.hasRelationalHistoricPage = true;
    } else if (newUserData.idLicense === "30") {
      newUserData.hasOportunityCloudPage = true;
      newUserData.hasProminentSupplication = true;
      newUserData.hasCultureIndicators = true;
      newUserData.hasRelationalPositionPage = true;
      newUserData.hasRelationalHistoricPage = true;
    } else if (newUserData.idLicense === "40") {
      newUserData.hasOportunityCloudPage = true;
      newUserData.hasProminentSupplication = true;
      newUserData.hasCultureIndicators = true;
      newUserData.hasRelationalPositionPage = true;
      newUserData.hasSupplicationDashboard = true;
      newUserData.hasRelationalHistoricPage = true;
    } else {
        newUserData.planClass = 'Admin';

        newUserData.hasOportunityCloudPage = true;
        newUserData.hasProminentSupplication = true;
        newUserData.hasCultureIndicators = true;
        newUserData.hasRelationalPositionPage = true;
        newUserData.hasSupplicationDashboard = true;
        newUserData.hasRelationalHistoricPage = true;
      }
  }

  return newUserData;
};
