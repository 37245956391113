import React, { useState, useContext, useEffect, useRef } from 'react';
import { getUserData, getRefreshToken } from 'services/getUserData';
import styled from 'styled-components';
import InternalLayout from 'components/InternalLayout';
import Loading from 'components/Loading';
import Filters from './Filters';
import Graph from './Graph';
import GraphSupplication from './GraphSupplication';
import '@bryntum/grid/grid.stockholm.css';
import { ExportButton, exportToPDF } from '../pdfExporter/pdfExporter'; // Adicionando a importação

import { RelativeIndicatorContext } from 'context/RelativeIndicator';

const Content = styled.div`
  > div {
    height: calc(100vh - 70px);
  }
`;

function RelationalHistoricPage() {
  const [user, setUser] = useState(null);
  const contentRef = useRef();

  useEffect(() => {
    getRefreshToken();
    setUser(getUserData());
  }, []);

  useEffect(() => {
    if (user) {
      if (!user.hasRelationalHistoricPage) {
        window.location = '#/login';
      }
    }
  }, [user]);

  const {
    ICREvolucao, getICREvolucao,
  } = useContext(RelativeIndicatorContext);
  const [tipoPesquisa, setTipoPesquisa] = useState('R');
  const [usedSearch, setUsedSearch] = useState();

  return (
    <InternalLayout
      title="Histórico relacional"
      toogledSearch={usedSearch}
      filters={
        <Filters onSearch={(payload) => {
          getICREvolucao({
            ...payload,
          });
          setTipoPesquisa(payload.tipoPesquisa);
          setUsedSearch(!usedSearch);
        }} />
      }
    >
      <Loading show={ICREvolucao.loading} size="big" />
      {ICREvolucao && ICREvolucao.length > 0 && (
        <ExportButton onClick={() => exportToPDF('content', false, "historico_relacional.pdf")}>Exportar</ExportButton>
      )}
      <Content id="content" ref={contentRef}>
        {(ICREvolucao.loading) ? (
          <p></p>
        ) : ICREvolucao && ICREvolucao.error ? (
          <p style={{margin: 20}}>Sem resultados para a busca</p>
        ) : (
          <>
            {tipoPesquisa === 'R' ? (
              <Graph
                graphData={ICREvolucao}
              />
            ) : (
              <GraphSupplication
                graphData={ICREvolucao}
              />
            )}
          </>
        )}
      </Content>
    </InternalLayout>
  );
}

export default RelationalHistoricPage;
