import React from "react";
import styled from 'styled-components';
import Sitting from 'assets/capital/sitting-8.svg';
import Sitting2 from 'assets/capital/sitting-2.svg';
import Notebook from 'assets/capital/notebook.svg';

import Footer from 'components/Landing/Footer';
import Contact from 'components/Landing/Contact';
import Header from 'components/Landing/Header';
import Onboarding from 'components/Landing/Onboarding';
import Humans from 'components/Landing/Humans';

import {
  Body, BgHeader, BannerWrapper,
  BannerText, BannerBottomText, Button,
  NaMidia, Suplicas, CardsWrapper, CardsList,
  Card, Market, FormulaBanner,
  FormulaBannerFirst, FormulaBannerRounded, BestPlan,
  Plataforma, PlataformaBoxes, WomanBannerWrapper,
  WomanBannerText, Strategy, FullCards,
  Plans, Plan, FormulaBannerRoundedGreen,
  ButtonPlan, LongText, CardsListReverse,
} from './atoms';

const B180 = styled.b`
  font-size: 180px;

  @media(max-width: 800px) {
    font-size: 130px;
  }
`;

const B150 = styled.b`
  font-size: 150px;

  @media(max-width: 800px) {
    font-size: 110px;
  }
`;

function Home() {
  const scrollToContact = () => {
    document.getElementById('contact').scrollIntoView();
  }

  return (
    <Body >
      <BgHeader>
        <Header />
        <BannerWrapper>
          <div>
            <BannerText>
              <b>Relação</b>&<br />
              <b>Resultado</b>&<br />
              <b>Humanização</b>&<br />
              <b>CapitalRelacional</b><br />
            </BannerText>
            <BannerBottomText>Um conceito inovador para medir a qualidade das relações humanas nas organizações</BannerBottomText>
            <Button onClick={scrollToContact} >QUERO UMA DEMONSTRAÇÃO</Button>
          </div>
          <img className="illustration" src={Sitting} alt="Capital Relacional - Um conceito inovador para medir a qualidade das relações humanas nas organizações" />
        </BannerWrapper>
      </BgHeader>
      <NaMidia>
        <p>Capital Relacional na mídia</p>
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </NaMidia>
      <Suplicas>
        <h2>Você conhece as <em>súplicas</em> dos seus clientes, líderes e colaboradores?</h2>
        <p>Conhecendo as súplicas da equipe e do cliente é possível medir a qualidade das relações e implementar um modelo de gestão onde as líderanças trabalham com informações inéditas capazes de gerar aprendizado para elevar o engajamento da equipe, a recorrência das vendas e as margens de resultado.</p>
      </Suplicas>
      <CardsWrapper>
        <CardsList>
          <Card className="card">
            <div>
              <span>dê-me</span>
              <p>
                <B180>auto</B180><br />
                <B150>no</B150><br />
                <B150>mia</B150><br />
              </p>
            </div>
            <LongText className="long-text">
              Conectar a oferta com a demanda é um desafio para todos os negócios. O pacote pronto não atende necessidades específicas. E se tem uma coisa de que nenhum cliente gosta é não ver nenhuma flexibilização a seu favor.
            </LongText>
          </Card>
          <Card className="card">
            <div>
              <span>esteja</span>
              <p>
                <B180>dis</B180><br />
                <B150>po</B150><br />
                <B150>nível</B150><br />
              </p>
            </div>
            <LongText className="long-text">
              Compromisso gera compromisso. Manter-se acessível entre uma venda e outra faz parte da realidade de quem pensa clientes para o resto da vida e não apenas para um só pedido. Presença gera presença. Se quem vende se mostrar disponível, é claro que ele se manterá presente na mente (e no coração) do cliente.
            </LongText>
          </Card>
          <Card className="card">
            <div>
              <p>
                <B180>co</B180><br />
                <B150>nhe</B150><br />
                <B150>ça</B150><br />
              </p>
              <span>-me</span>
            </div>
            <LongText className="long-text">
            Alguns até podem almejar o anonimato, mas a maioria dos clientes gosta de ser conhecido e reconhecido, considerado, prestigiado. Desde a escuta elementar do próprio nome até a oferta de gestos de gentileza como os oferecidos a quem visita a nossa casa.
            </LongText>
          </Card>
          <Card className="card">
            <div>
              <p>
                <B180>ins</B180><br />
                <B150>pi</B150><br />
                <B150>re</B150><br />
              </p>
              <span>-me</span>
            </div>
            <LongText className="long-text">
            A inspiração é a mãe da imaginação e a imaginação, por sua vez, é o reino das possibilidades infinitas, onde só existe lugar para a abundância, não para a escassez. É nela que buscaremos - e encontraremos - as soluções para as nossas infelicidades do dia a dia. Mas saiba, embora de muita sutilize e quase invisível, ela é muito humana e desejada.
            </LongText>
          </Card>
        </CardsList>
        <CardsListReverse>
          <Card className="card">
            <div>
              <p>
                <B150>ori</B150><br />
                <B150>ente</B150>
              </p>
              <span>-me</span>
            </div>
            <LongText className="long-text">
              Não subestime a inteligência do cliente, mas jamais superestime seus conhecimentos. O cliente tem inteligência suficiente para aprender, mas é preciso informá-lo e, muitas vezes, educá-lo. E sem esperar solicitações. Ele quer a resposta, mas em sempre sabe formular a pergunta.
            </LongText>
          </Card>
          <Card className="card">
            <div>
              <p>
                <B180>faci</B180><br />
                <B150>lite</B150>
              </p>
              <span>a minha</span>
              <p><B150>vida</B150></p>
            </div>
            <LongText className="long-text">
              O cliente gosta de ir direto ao ponto, sem rodeios. Aprecia quando você fala a língua dele, sem sobrecarregá-lo para que ele compreenda a sua. Fica feliz quando você o espera e não o contrário e reconhece quando valoriza o tempo dele. Espera que tudo seja simples e descomplicado.
            </LongText>
          </Card>
          <Card className="card">
            <div>
              <p>
                <B180>con</B180><br />
                <B150>quiste</B150>
              </p>
              <span>a minha</span>
              <p>
                <B150>confi</B150><br />
                <B150>ança</B150>
              </p>
            </div>
            <LongText className="long-text">
              Confiança não é algo que se tem ou um ambiente onde se trabalha. Confiança é algo que se exerce e se pratica por meio das relações. Criar a confiança, mantê-la ou restabelecê-la é um exercício contínuo que se faz com a prática do Capital Relacional.
            </LongText>
          </Card>
          <Card className="card">
            <div>
              <p>
                <B180>sur</B180><br />
                <B150>pre</B150><br />
                <B150>enda</B150>
              </p>
              <span>-me</span>
            </div>
            <LongText className="long-text">
              No mundo dos negócios, há os que ainda acreditam que as demandas e os clientes giram ao redor de seus produtos, não o inverso. Vivem um geocentrismo empresarial. É necessário compreender que o astro-rei é o cliente, e é ele que está no centro do universo dos negócios e, ao seu redor, giram os produtos e os serviços, tanto os próprios quanto os dos concorrentes.
            </LongText>
          </Card>
        </CardsListReverse>
      </CardsWrapper>
      <Market>
        <p>
          Mercados são negócios. Negócios são conversas.<br />
          Conversas são pessoas. Pessoas são relacionamentos.
        </p>
        <Button onClick={scrollToContact}>QUERO UMA DEMONSTRAÇÃO</Button>
      </Market>
      <FormulaBanner>
        <div>
          <FormulaBannerFirst>
            <p><b>a nova</b></p>
            <p>fórmula do</p>
            <em>LUCRO</em>
          </FormulaBannerFirst>
          <div>=</div>
          <FormulaBannerRounded><span><b>EQUIPE</b> COMPROMETIDA</span></FormulaBannerRounded>
          <div>+</div>
          <FormulaBannerRoundedGreen><span><b>CLIENTE</b> FIDELIZADO</span></FormulaBannerRoundedGreen>
        </div>
      </FormulaBanner>
      <Plataforma>
        <h2>Plataforma para gestão relacional</h2>
        <p>Desenvolva uma <b>estratégia empreendedora</b> de <b>relacionamento</b> e <b>resultado</b> para sua empresa</p>
        <img src={Notebook} alt="Capita relacional notebook" style={{ maxWidth: '100%' }} />
        <PlataformaBoxes>
          <div>
            <h3><b>Descubra as oportunidades para construir experiências impactantes.</b></h3>
            <p>Com um dashboard descomplicado, você gerencia desde o índice de qualidade das relações até pontos de oportunidades para criar experiências impactantes</p>
          </div>
          <div>
            <h3><b>Desenvolva competências relacionais na sua equipe.</b></h3>
            <p>Além de gerir o nível de atenção e interesse dos clientes e da equipe, a liderança terá ferramentas que indicam as principais competências que precisam ser desenvolvidas para cuidar das relações.</p>
          </div>
          <div>
            <h3><b>Melhore a qualidade do diálogo com os seus clientes e sua equipe.</b></h3>
            <p>O compromisso emocional e o alinhamento entre equipe e cliente são o elo consistente dos negócios e dos resultados.</p>
          </div>
        </PlataformaBoxes>
        <Button onClick={scrollToContact} full>QUERO UMA DEMONSTRAÇÃO</Button>
      </Plataforma>
      <WomanBannerWrapper>
        <WomanBannerText>
          <h3>Ca.pi.tal<br />Re.la.cio.nal</h3>
          <p>Estratégia geradora de resultados na Nova Economia que define o lucro como equipe comprometida + cliente fidelizado</p>
        </WomanBannerText>
        <img src={Sitting2} alt="human woman sitting" />
      </WomanBannerWrapper>
      <Strategy>
        <h2>Informações estratégicas</h2>
        <p>Nossa plataforma oferece informações que geram aprendizados para elevar a qualidade das relações, o potencial empreendedor da equipe e os resultados do negócio.</p>
        <ul>
          <li>
            <div>
              <h4>Nuvem de percepções</h4>
              <p>Revela a qualidade do relacionamento com cada tipo de relação, a qualidade do engajamento e os pontos de oportunidades para elevar o nível de atendimento de cada súplica.</p>
            </div>
          </li>
          <li>
            <div>
              <h4>Indicadores relacionais</h4>
              <p>Revela padrões e diferenças de atendimento das súplicas existentes entre os tipos de relação. Pode sinalizar padrões culturais e competências não reconhecidas pela organização.</p>
            </div>
          </li>
          <li>
            <div>
              <h4>Súplicas em destaque</h4>
              <p>Revela a capacidade da empresa para atendimento de cada súplica.</p>
            </div>
          </li>
          <li>
            <div>
              <h4>Camadas Relacionais</h4>
              <p>Revela as diferenças nos padrões de relacionamento existentes entre os grupos de respondentes que compõem cada camada de negócio.</p>
            </div>
          </li>
          <li>
            <div>
              <h4>Competência relacional</h4>
              <p>Revela, por camada de negócio, a necessidade de desenvolvimento de competência relacional para atendimento de cada súplica.</p>
            </div>
          </li>
          <li>
            <div>
              <h4>Cultura relacional</h4>
              <p>Revela a consistência da cultura de relacionamento e resultado presente na organização.</p>
            </div>
          </li>
          <li>
            <div>
              <h4>Posicionamento relacional</h4>
              <p>Revela o posicionamento dos respondentes a respeito do grau de atenção e interesse que é percebido na relação com o negócio.</p>
            </div>
          </li>
          <li>
            <div>
              <h4>Painel das súplicas</h4>
              <p>Revela o desafio da relação com cada respondente.</p>
            </div>
          </li>
          <li>
            <div>
              <h4>Boa conversa</h4>
              <p>Revela os pontos de atenção que ajudarão a orientar a boa conversa.</p>
            </div>
          </li>
          <li>
            <div>
              <h4>Histórico relacional</h4>
              <p>Revela o capital relacional gerado ao longo do tempo.</p>
            </div>
          </li>
        </ul>
        <Button full>QUERO UMA DEMONSTRAÇÃO</Button>
      </Strategy>
      <Strategy>
        <h2>O que a sua empresa ganha?</h2>
        <p>Com o Capital Relacional como estratégia de negócio a sua empresa ganha:</p>
      </Strategy>
      <FullCards>
        <div>Um <b>modelo de gestão e aprendizado</b> capaz de desenvolver uma equipe mais empreendedora e engajada com os desafios do negócio.</div>
        <div>Fortalecimento de uma <b>cultura empreendedora de relacionamento e resultado.</b></div>
        <div><b>Informações estratégicas</b> e oportunidades para criar <b>experiências impactantes</b> e elevar a reputação da sua marca.</div>
        <div>Uma <b>dinâmica</b> ainda mais <b>eficiente</b> de <b>alavancar e avaliar resultados.</b></div>
      </FullCards>
      <Strategy>
        <Button onClick={scrollToContact} full>QUERO UMA DEMONSTRAÇÃO</Button>
      </Strategy>
      <Onboarding onClickButton={scrollToContact} />
      <Strategy>
        <h2>Planos disponíveis</h2>
        <p>Oferecemos 3 modalidades de planos de assinaturas mensais para encaixar na sua empresa.</p>
      </Strategy>
      <Plans>
        <Plan>
          <h3>Muacy</h3>
          <p>Para empresas que optam por manter o Capital Relacional saudável para não perder resultados.</p>
          <ButtonPlan>QUERO UMA DEMONSTRAÇÃO</ButtonPlan>
        </Plan>
        <BestPlan>
          <div>MAIS ESCOLHIDO</div>
          <h3>Kiran</h3>
          <p>Para empresas que desejam alto rendimento do seu Capital Relacional obtendo resultados de forma estratégica.</p>
          <ButtonPlan yellowed>QUERO UMA DEMONSTRAÇÃO</ButtonPlan>
        </BestPlan>
        <Plan blued>
          <h3>Jarina</h3>
          <p>Para empresas que preferem trabalhar o  Capital Relacional apenas na esfera operacional.</p>
          <ButtonPlan blued>QUERO UMA DEMONSTRAÇÃO</ButtonPlan>
        </Plan>
      </Plans>
      <Strategy>
        <p>Quer saber mais sobre os planos? <a href="#">Clique aqui</a>.</p>
      </Strategy>
      <Humans />
      <div id="contact"></div>
      <Contact />
      <Footer />
    </Body>
  );
};

export default Home;
