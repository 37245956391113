import React, { useState, useContext, useEffect, useRef }  from 'react';
import { getUserData, getRefreshToken } from 'services/getUserData';
import styled from 'styled-components';
import InternalLayout from 'components/InternalLayout';
import Filters from 'containers/Filters/AgentsAndCamadaNegocio'
import Loading from 'components/Loading';
import Graph from './Graph'
import '@bryntum/grid/grid.stockholm.css';
import { ExportButton, exportToPDF } from '../pdfExporter/pdfExporter'; // Adicionando a importação

import { RelativeIndicatorContext } from 'context/RelativeIndicator';

const Content = styled.div`
  > div {
    height: calc(100vh - 70px);
  }
`

function CultureIndicatorsPage() {
  const [user, setUser] = useState(null);
  const contentRef = useRef();

  useEffect(() => {
    getRefreshToken();
    setUser(getUserData());
  }, []);

  useEffect(() => {
    if (user) {
      if (!user.hasCultureIndicators) {
        window.location = '#/login';
      }
    }
  }, [user]);

  const {
    icrCamadas, getICRCamadas,
  } = useContext(RelativeIndicatorContext);
  const [usedSearch, setUsedSearch] = useState();

  return (
    <InternalLayout
      title="Cultura Relacional"
      toogledSearch={usedSearch}
      filters={
        <Filters onSearch={(payload) => {
          getICRCamadas({
            ...payload,
            visualizacao: 'relacao',
            analiseRelacoes: 1
          });
          setUsedSearch(!usedSearch);
        }} />
      }
    >
      {icrCamadas && icrCamadas.data && icrCamadas.data.length > 0 && (
        <ExportButton onClick={() => exportToPDF('content', false, "cultura_relacional.pdf")}>Exportar</ExportButton>
      )}
      <Content id="content" ref={contentRef}>
        {icrCamadas.loading ? (
          <Loading show={icrCamadas.loading} size="big" />
        ) : icrCamadas && icrCamadas.data && !icrCamadas.data.length ? (
          <p style={{margin: 20}}>Sem resultados para a busca</p>
        ) : (
          <Graph
            graphData={icrCamadas}
          />
        )}
      </Content>
    </InternalLayout>
  );
}

export default CultureIndicatorsPage;
