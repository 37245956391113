import React, { useLayoutEffect, useRef, memo } from "react"
import * as am5 from "@amcharts/amcharts5";
import * as am5wc from "@amcharts/amcharts5/wc";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

am5.addLicense("AM5C303720496");

const cores = {
  "success": 0x34c38f,
  "warning": 0xf1b44c,
  "danger": 0xf46a6a,
  "black": 0x121212
};

const Grafico = ({ dados, id, idAgenteHolograma }) => {
    const dadosGrafico = dados || [];

    useLayoutEffect(() => {
        if (dadosGrafico.length > 0) {
            document.querySelector(`#${id}`).innerHTML = "";
            var root = am5.Root.new(id);

            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/
            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            // Add series
            // https://www.amcharts.com/docs/v5/charts/word-cloud/
            var series = root.container.children.push(am5wc.WordCloud.new(root, {
              calculateAggregates: true,
              maxFontSize: am5.percent(70),
              minFontSize: am5.percent(10)
            }));

            // Had to set a heatRules for tooltips to work right
            series.set("heatRules", [{
              target: series.labels.template,
              dataField: "value",
              min: am5.color(0x999999),
              max: am5.color(0x000000),
              key: "fill"
            }]);

            series.labels.template.setAll({
              fontFamily: "Leelawadee",
              tooltipText: "[fontFamily: Leelawadee color=#fff fontSize:14px]{icr_nome_suplica}[/]"
            });

            /*
            var colorSet = am5.ColorSet.new(root, { step: 1 });

            series.labels.template.setup = function(label) {
              label.set("background", am5.RoundedRectangle.new(root, { fillOpacity: 1, fill: colorSet.next() }))
            }
            */
           
            let dados = [];

            dadosGrafico.filter(a => a.icr_id_agente_holograma == idAgenteHolograma).forEach((elemento, index) => {
              dados.push( { icr_nome_suplica: elemento.icr_nome_suplica,
                            category: elemento.icr_frases_palavras,
                            value: elemento.icr_quantidade } );
            });

            series.data.setAll(dados);
        }

    }, [dados])


    return (
      <div id={id} style={{ width: "100%", minHeight: "100%", opacity: 0 }}></div>
    )
}

export default memo(Grafico)
