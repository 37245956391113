import React, { useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5radar from "@amcharts/amcharts5/radar";
import { returnaAlturaTotalElementos, returnaTotalMedidasVerticais  } from "../../utils";

const Graph = ({ variant, children, graphData, ...props }) => {
  const chartReferencia = useRef(null);
  const dadosGrafico = graphData.data || [];

  useLayoutEffect(() => {
    if (dadosGrafico.length > 0) {
      const cores = {
          "success": "#34c38f",
          "warning": "#f1b44c",
          "danger": "#f46a6a"
      };

      document.querySelector(`#chartdiv`).innerHTML = "";

      var root = am5.Root.new("chartdiv");

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
          am5themes_Animated.new(root)
      ]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/radar-chart/
      var chart = root.container.children.push(am5radar.RadarChart.new(root, {
          // panX: false,
          // panY: true,
          startAngle: 180,
          endAngle: 360,
          // radius: am5.percent(100)
          //layout: root.verticalLayout
          // paddingTop: 110,
          // paddingBottom: 0,
          // margimBottom: 0
            radius: am5.percent(90),
            innerRadius: am5.percent(50)
      }));

      // Colors
      var colors = am5.ColorSet.new(root, {
          step: dadosGrafico.length + 1
      });

      chart.get("colors").set("colors", [
        am5.color(0x264653),
        am5.color(0xE97C61),
        am5.color(0xE9C46A),
        am5.color(0x8AB17D),
        am5.color(0x277DA1),
        am5.color(0x2A9D8F),
        am5.color(0xE76F51),
        am5.color(0x287271),
        am5.color(0xEFB366),
        am5.color(0xEE8959)
      ]);

      var axisRenderer1 = am5radar.AxisRendererCircular.new(root, {
        radius: -10,
        strokeGradient: am5.LinearGradient.new(root, {
          rotation: 0,
          stops: [
            { color: am5.color(0xf46a6a) },
            { color: am5.color(0xf46a6a) },
            { color: am5.color(0xf46a6a) },
            { color: am5.color(0xf46a6a) },
            { color: am5.color(0xf46a6a) },
            { color: am5.color(0xf1b44c) },
            { color: am5.color(0xf1b44c) },
            { color: am5.color(0xf1b44c) },
            { color: am5.color(0x34c38f) }
          ]
        }),
        strokeOpacity: 1,
        strokeWidth: 10,
        inside: true
      });

      axisRenderer1.grid.template.setAll({
        forceHidden: true
      });

      axisRenderer1.ticks.template.setAll({
        stroke: am5.color(0X9092a1),
        visible: true,
        length: 10,
        strokeOpacity: 0,
        strokeWidth: 3,
        inside: true
      });

      axisRenderer1.labels.template.setAll({
        radius: 15,
        inside: true,
        opacity: 0
      });

      var xAxis1 = chart.xAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: -100,
        max: 100,
        strictMinMax: true,
        renderer: axisRenderer1
      }));

      var legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        y: 15
      }));

      let itensGrafico = [];
      dadosGrafico.map((dado, indice) => {
        itensGrafico.push({});
        let itemGrafico = itensGrafico[itensGrafico.length - 1]

        itemGrafico.relacao_resultado = dado.relacao_resultado

        itemGrafico.color1 = chart.get("colors").next();//colors.next();

        itemGrafico.axisDataItem1 = xAxis1.makeDataItem({
          value: 0,
          fill: itemGrafico.color1,
          //name: `[fontFamily: Leelawadee fontSize: 20px]${dado.icr_descricao}[/]  [fontFamily: Leelawadee fontSize: 20px bold]${dado.relacao_icr}[/]`
          name: `[fontSize: 16px fontFamily: Leelawadee ]${dado.icr_descricao}[/] [fontSize: 16px fontFamily: Leelawadee](${dado.pesquisas_feitas} resp.)[/]`,

        });

        itemGrafico.clockHand1 = am5radar.ClockHand.new(root, {
          pinRadius: 24,
          radius: am5.percent(98),
          bottomWidth: 20
        });

        itemGrafico.clockHand1.pin.setAll({
          fill: itemGrafico.color1
        });

        itemGrafico.clockHand1.hand.setAll({
          fill: itemGrafico.color1
        });

        itemGrafico.bullet1 = itemGrafico.axisDataItem1.set("bullet", am5xy.AxisBullet.new(root, {
          sprite: itemGrafico.clockHand1
        }));

        xAxis1.createAxisRange(itemGrafico.axisDataItem1);

        itemGrafico.axisDataItem1.get("grid").set("forceHidden", true);
        itemGrafico.axisDataItem1.get("tick").set("forceHidden", true);
      })

      let legendas = [];

      itensGrafico.map(item => {
        legendas.push(item.axisDataItem1);
      })

      legend.data.setAll(legendas);

      var DDD = chart.children.push(am5.Label.new(root, {
        text: "DDD",
        fontWeight: "900",
        y: am5.percent(100),
        centerY: am5.percent(100),
        x: am5.percent(50),
        centerX: am5.percent(92),
        width: am5.p100,
        textAlign: "center",
        fill: am5.color(0xf46a6a)
      }));

      var AiA = chart.plotContainer.children.push(am5.Label.new(root, {
        text: "AiA",
        fontWeight: "900",
        y: am5.percent(100),
        centerY: am5.percent(100),
        x: am5.percent(50),
        centerX: am5.percent(8),
        width: am5.p100,
        textAlign: "center",
        fill: am5.color(0x34c38f)
      }));

      // Animate values
      setTimeout(function () {
        itensGrafico.map(item => {
          item.axisDataItem1.animate({
            key: "value",
            to: item.relacao_resultado,
            duration: 1000,
            easing: am5.ease.out(am5.ease.cubic)
          });
        })
      }, 1000)
      // Make stuff animate on load
      chart.appear(1000, 100);
    }
  }, [graphData])

  let alturaFinal;

  if (window.outerHeight < 600) {
      alturaFinal = `calc(100vh)`;
  } else {
      alturaFinal = `calc(100vh - 70px)`;
  }

  return (
    <div>
      <div id="chartdiv" style={{ width: "100%", minHeight: alturaFinal }}></div>
    </div>
  );
};

export default React.memo(Graph);
