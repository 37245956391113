export default {
  "ResultadoObjeto": [
    {
      "id": "300010",
      "nome": "Atalhos",
      "icone": "",
      "url": "",
      "urlAmigavel": "",
      "favorito": false,
      "recente": false,
      "novo": false,
      "badgeTexto": "",
      "badgeTema": "",
      "filhoAberto": false,
      "subMenu": [
        {
          "id": "14",
          "nome": "Respondentes",
          "icone": "fad fa-address-card",
          "url": "",
          "urlAmigavel": "",
          "favorito": false,
          "recente": false,
          "novo": false,
          "badgeTexto": "",
          "badgeTema": "",
          "filhoAberto": false,
          "subMenu": null
        },
        {
          "id": "15",
          "nome": "Convites",
          "icone": "fad fa-people-arrows",
          "url": "",
          "urlAmigavel": "",
          "favorito": false,
          "recente": false,
          "novo": false,
          "badgeTexto": "",
          "badgeTema": "",
          "filhoAberto": false,
          "subMenu": null
        },
        {
          "id": "16",
          "nome": "Convites em lote",
          "icone": "fad fa-file-download",
          "url": "",
          "urlAmigavel": "",
          "favorito": false,
          "recente": false,
          "novo": false,
          "badgeTexto": "",
          "badgeTema": "",
          "filhoAberto": false,
          "subMenu": null
        },
        {
          "id": "10",
          "nome": "Vídeos ",
          "icone": "fab fa-youtube",
          "url": "",
          "urlAmigavel": "/https://youtube.com/playlist?list=PLFF7eD2FaPLcE6KQDqgmI6Ttkce7cfkLk/",
          "favorito": false,
          "recente": false,
          "novo": false,
          "badgeTexto": "",
          "badgeTema": "",
          "filhoAberto": false,
          "subMenu": null
        },
        {
          "id": "11",
          "nome": "Artigos ",
          "icone": "fad fa-blog",
          "url": "",
          "urlAmigavel": "/https://www.robertotranjan.com.br/categoria/capitalrelacional//",
          "favorito": false,
          "recente": false,
          "novo": false,
          "badgeTexto": "",
          "badgeTema": "",
          "filhoAberto": false,
          "subMenu": null
        },
        {
          "id": "12",
          "nome": "@capitalrelacional",
          "icone": "fab fa-instagram",
          "url": "",
          "urlAmigavel": "/https://www.instagram.com/capitalrelacional//",
          "favorito": false,
          "recente": false,
          "novo": false,
          "badgeTexto": "",
          "badgeTema": "",
          "filhoAberto": false,
          "subMenu": null
        },
        {
          "id": "8",
          "nome": "Nuvem de percepções",
          "icone": "cloud",
          "url": "",
          "urlAmigavel": "/nuvem-de-percepcoes",
          "favorito": false,
          "recente": false,
          "novo": false,
          "badgeTexto": "",
          "badgeTema": "",
          "filhoAberto": false,
          "subMenu": null
        },
        {
          "id": "3",
          "nome": "Indicadores relacionais",
          "icone": "usersClass",
          "url": "",
          "urlAmigavel": "/indicadores-relacionais/",
          "favorito": false,
          "recente": false,
          "novo": false,
          "badgeTexto": "",
          "badgeTema": "",
          "filhoAberto": false,
          "subMenu": null
        },
        {
          "id": "6",
          "nome": "Súplicas em destaque",
          "icone": "pray",
          "url": "",
          "urlAmigavel": "/suplicas-em-destaque/",
          "favorito": false,
          "recente": false,
          "novo": false,
          "badgeTexto": "",
          "badgeTema": "",
          "filhoAberto": false,
          "subMenu": null
        },
        {
          "id": "13",
          "nome": "Camadas Relacionais",
          "icone": "pollPeople",
          "url": "",
          "urlAmigavel": "/camadas-relacionais/",
          "favorito": false,
          "recente": false,
          "novo": false,
          "badgeTexto": "",
          "badgeTema": "",
          "filhoAberto": false,
          "subMenu": null
        },
        {
          "id": "5",
          "nome": "Competência relacional",
          "icone": "fireplace",
          "url": "",
          "urlAmigavel": "/competencia-relacional/",
          "favorito": false,
          "recente": false,
          "novo": false,
          "badgeTexto": "",
          "badgeTema": "",
          "filhoAberto": false,
          "subMenu": null
        },
        {
          "id": "7",
          "nome": "Cultura relacional",
          "icone": "streetView",
          "url": "",
          "urlAmigavel": "/cultura-relacional/",
          "favorito": false,
          "recente": false,
          "novo": false,
          "badgeTexto": "",
          "badgeTema": "",
          "filhoAberto": false,
          "subMenu": null
        },
        {
          "id": "4",
          "nome": "Posicionamento relacional",
          "icone": "diagnoses",
          "url": "",
          "urlAmigavel": "/posicionamento-relacional/",
          "favorito": false,
          "recente": false,
          "novo": false,
          "badgeTexto": "",
          "badgeTema": "",
          "filhoAberto": false,
          "subMenu": null
        },
        {
          "id": "1",
          "nome": "Painel das súplicas",
          "icone": "userVisor",
          "url": "",
          "urlAmigavel": "/painel-das-suplicas/",
          "favorito": false,
          "recente": false,
          "novo": false,
          "badgeTexto": "",
          "badgeTema": "",
          "filhoAberto": false,
          "subMenu": null
        },
        {
          "id": "9",
          "nome": "Histórico relacional",
          "icone": "userChart",
          "url": "",
          "urlAmigavel": "/historico-relacional/",
          "favorito": false,
          "recente": false,
          "novo": false,
          "badgeTexto": "",
          "badgeTema": "",
          "filhoAberto": false,
          "subMenu": null
        }
      ]
    }
  ],
  "Erros": []
};
