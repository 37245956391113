import styled from 'styled-components';

export const Body = styled.div`
  background-image: linear-gradient(to bottom right, #114270, #1E7ABD);
  background-color: #FFF;
  background-repeat:no-repeat;
  background-size:100% 210px;
  position: absolute;
  width: 100%;
`;

export const Strategy = styled.div`
  color: #0F355F;
  max-width: 1240px;
  margin: 50px auto;

  h2 {
    font-size: 68px;
    margin-bottom: 48px;
    color: #0F355F;
  }

  p {
    font-size: 30px;
  }
`;

export const StrategyBanner = styled.div`
  color: #FFF;
  background: #089CA3;
  position: relative;
  width: 100vw;

  > div {
    max-width: 1240px;
    margin: 10px auto;

    p {
      max-width: calc(100% - 300px);
      padding: 35px 0;
      font-size: 30px;
      font-weight: bold;
    }

    img {
      position: absolute;
      right: 0;
      top: -30px;
    }
  }
`;

export const Badge = styled.img`
  position: absolute;
  top: 1px;
  right: 14px;
`;

export const Plans = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  max-width: 1240px;
  margin: 70px auto;

  @media(max-width: 900px) {
    flex-flow: column nowrap;
  }
`;

export const Plan = styled.div`
  width: 300px;
  background: ${props => props.bg ? props.bg : ' #07B5BE'};
  padding: 38px 10px 0;
  color: #FFF;
  font-size: 16px;
  height: 250px;
  border-radius: 5px;

  h3 {
    color: #FFF;
    font-size: 46px;
    font-weight: bold;
    padding-bottom: 12px;
  }

  p {
    padding-bottom: 30px;
  }

  @media(max-width: 900px) {
    margin: 40px;
    width: 100%;
  }
`;

export const BestPlan = styled(Plan)`
  background: #F07F35;
  position: relative;

  > div {
    position: absolute;
    display: inline-block;
    font-weight: bold;
    background: #FACD1B;
    font-size: 16px;
    color: #000000;
    padding: 15px;
    top: -44px;
    border-radius: 5px;
    right: 76px;
    width: 150px;
  }
`;

export const Resources = styled.div`
  background: #F7F7F7;

  > div {
    max-width: 1240px;
    margin: 0 auto;
    padding: 25px 0;

    h3 {
      color: #0F355F;
      font-weight: bold;
      font-size: 40px;
      padding-left: 20px;
    }

    table {
      width: 100%;
      border-collapse:separate;
      border-spacing: 18px 3px;
      table-layout: fixed ;

      th, td {
        max-width: 25%;
        height: 75px;
        border-radius: 5px;
      }

      th {
        background: #0F355F;
        font-size: 20px;
        font-weight: normal;
        color: #FFF;
        padding-left: 12px;

      }

      td {
        background: #EDEFF1;
        text-align: center;
        font-weight: bold;
        font-size: 24px;

        img + img {
          margin-left: 10px;
        }

        :nth-child(2) {
          color: #07B5BE;
        }

        :nth-child(3) {
          color: #1F7CBF;
        }

        :nth-child(4) {
          color: #F07F35;
        }
      }
    }
  }
`;

export const ResourcesWhite = styled(Resources)`
  background: #FFF;
`;

export const Dot = styled.div`
  background: #CBD1D5;
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 30px;
`;

export const DotGreen = styled(Dot)`
  background: #07B5BE;
`;

export const DotBlue = styled(Dot)`
  background: #1F7CBF;
`;

export const DotOrange = styled(Dot)`
  background: #F07F35;
`;

export const Button = styled.button`
  font-weight: bold;
  background: #47D2A4;
  border-radius: 5px;
  border: 0;
  color: #000;
  padding: 20px 40px;
  margin: 20px;
  width: ${props => (props.full) ? 'calc(100% - 40px)' : 'auto'};
  font-size: 16px;

  :hover {
    opacity: 0.8;
  }

  @media(max-width: 1365px) {
    padding: 16px 30px;
  }
`
