import axios from 'axios';
import queryString from 'query-string';
import { getData } from 'utils/token';
import { apiRoute } from 'env';

const validateEspired = (status) => {
  if (status === 401) {
    window.location = '#/login';
  }
}

export const getUserData = async () => {
  const data = getData();

  try {
    const response = await axios({
      url: `${apiRoute}/icr_variaveis/api/retornar-dados-usuario`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    return {
      success: true,
      data: response?.data?.resultadoObjeto || []
    };
  } catch(e) {
    validateEspired(e?.response?.status);
    const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Serviço indisponível';

    return {
      success: false,
      message
    };
  }
};

export const getParceiros = async () => {
  const data = getData();

  try {
    const response = await axios({
      url: `${apiRoute}/vw_parceiros_json`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    return {
      success: true,
      data: response?.data?.resultadoObjeto || []
    };
  } catch(e) {
    validateEspired(e?.response?.status);
    const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Serviço indisponível';

    return {
      success: false,
      message
    };
  }
};

export const getClientes = async (payload) => {
  const data = getData();

  try {
    const response = await axios({
      url: `${apiRoute}/vw_clientes_json?idParceiro=${payload.idParceiro}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    return {
      success: true,
      data: response?.data?.resultadoObjeto || []
    };
  } catch(e) {
    validateEspired(e?.response?.status);
    const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Serviço indisponível';

    return {
      error: true,
      message
    };
  }
};

export const getPeriodos = async (payload) => {
  const data = getData();

  try {
    const response = await axios({
      url: `${apiRoute}/vw_periodos_respostas_json?idParceiro=${payload.idParceiro}&idCliente=${payload.idCliente}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    return {
      success: true,
      data: response?.data?.resultadoObjeto || []
    };
  } catch(e) {
    const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Serviço indisponível';

    return {
      error: true,
      message
    };
  }
};

export const getAgentes = async (payload) => {
  const data = getData();

  try {
    const response = await axios({
      url: `${apiRoute}/vw_agentes_holograma_cliente_json?idParceiro=${payload.idParceiro}&idCliente=${payload.idCliente}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    return {
      success: true,
      data: response?.data?.resultadoObjeto || []
    };
  } catch(e) {
    validateEspired(e?.response?.status);
    const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Serviço indisponível';

    return {
      error: true,
      message
    };
  }
};

export const postCamadas = async (payload) => {
  const data = getData();
  const query = queryString.stringify(payload);

  try {
    const response = await axios({
      url: `${apiRoute}/vw_camadas_json?${query}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    return {
      success: true,
      data: response?.data?.resultadoObjeto || []
    };
  } catch(e) {
    validateEspired(e?.response?.status);
    const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Serviço indisponível';

    return {
      error: true,
      message
    };
  }
};

export const postCamadasNegocio = async (payload) => {
  const data = getData();
  const query = queryString.stringify(payload);

  try {
    const response = await axios({
      url: `${apiRoute}/vw_camadas_negocio_json?${query}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${data.jwt}`,
      },
    });

    return {
      success: true,
      data: response?.data?.resultadoObjeto || []
    };
  } catch(e) {
    validateEspired(e?.response?.status);
    const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Serviço indisponível';

    return {
      error: true,
      message
    };
  }
};
