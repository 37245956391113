import styled, { createGlobalStyle } from 'styled-components'

const ThemeStyle = createGlobalStyle`
  body {
    margin: 0;
    font-size: 16px;
    font-family: 'Leelawadee', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media screen and (max-width: 767px) {
      input[type="text"],
      input[type="number"],
      input[type="email"],
      input[type="tel"],
      input[type="password"] {
        font-size: 16px;
      }
    }
  }

  code {
    font-family: 'Leelawadee', sans-serif;
  }

  a {
    color: #009051;
  }
`

export const LoadingBlock = styled.div`
  display: table-cell;
  height: 100vh;
  text-align: center;
  width: 100vw;
  vertical-align: middle;
`

export const LoadingPage = styled.div`
  background: RGBA(255,255,255,0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  img {
    animation: rotation 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

export default ThemeStyle;
