
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import {
    NumberFormat
} from '@bryntum/grid/grid.umd';
import moment from "moment"
import "moment/locale/pt-br";
import zipcelx from 'zipcelx';
moment.locale('pt-BR')
const moeda = new NumberFormat('9,999.99');
const inteiro = new NumberFormat('9,999');

export const retornarCorSuplicas = (valor) => {
    if (valor <= -0.01)
        return "danger"
    if (valor <= 61.79)
        return "warning"

    return "success"
}

export const retornarColunasExcel = (exportacao) => {
    let colunas = [];
    exportacao.filter(a => a.xls == 1).map(item => colunas.push({ text: item.titulo, field: item.campo }));
    return colunas;
}

export const exportarExcel = (dados) => {
    let colunas = [];
    let linhas = [];
    let exportacao = dados.exportacao.filter(a => a.xls == 1);
    exportacao.map(item => colunas.push({ value: item.titulo, type: "string" }));
    linhas.push(colunas);
    dados.data.map(item => {
        colunas = [];
        exportacao.map(item2 => {
            if (item2.tipo == "date") {
                colunas.push({ value: toDate(item[item2.campo]), type: item2.tipo })
            } else {
                colunas.push({ value: item[item2.campo], type: item2.tipo })
            }
        });
        linhas.push(colunas);
    });
    const config = {
        filename: localStorage.getItem("tituloBizAtual").trim(),
        sheet: {
            data: linhas
        }
    };
    zipcelx(config);
    return linhas;
}

export const retornarPercentual = (valor, valorParcial) => {
    if (valor == 0 || valorParcial == 0) return 0;
    return (valorParcial / valor) * 100;
}

export const retornarTipoCustos = () => {
    return [
        { value: "01", label: "Custo médio" },
        { value: "02", label: "Custo gerencial" }
    ]
};

export const retornarMeses = () => {
    return [
        { value: "01", label: "Janeiro" },
        { value: "02", label: "Fevereiro" },
        { value: "03", label: "Março" },
        { value: "04", label: "Abril" },
        { value: "05", label: "Maio" },
        { value: "06", label: "Junho" },
        { value: "07", label: "Julho" },
        { value: "08", label: "Agosto" },
        { value: "09", label: "Setembro" },
        { value: "10", label: "Outubro" },
        { value: "11", label: "Novembro" },
        { value: "12", label: "Dezembro" }
    ]
};

export const retornarTipoFluxoCaixa = () => {
    return [
        { key: 1, value: "D", label: "Diário" },
        { key: 2, value: "S", label: "Semanal" },
        { key: 3, value: "M", label: "Mensal" }
    ]
};

export const retornarTiposPeriodo = () => {
    return [
        { value: "1", label: "Mensal" },
        { value: "2", label: "Período" }
    ]
};


export const retornarAnos = (qtdeAnos, adicionando = false) => {
    let anos = [{ value: Ano(), label: Ano() }];
    for (let i = 1; i <= qtdeAnos; i++) {
        let ano = parseInt(Ano())
        if (adicionando)
            anos.push({ value: ano + i, label: ano + i });
        else
            anos.push({ value: ano - i, label: ano - i });
    }
    return anos;
};

export const retornarTipoDesempenho = () => {
    let desempenho = [];
    desempenho.push({ value: "unidade", label: "Unidade" });
    desempenho.push({ value: "negocio", label: "Negócio" });
    desempenho.push({ value: "rede", label: "Rede" });
    desempenho.push({ value: "cliente", label: "Loja" });
    return desempenho;
};


export const createThumb = (e, callback) => {
    e.preventDefault();
    if (!e.target.files[0])
        return
    const file = new FileReader();
    const arquivo = e.target.files[0];
    file.readAsDataURL(arquivo);

    file.onloadend = (event) => {

        callback(event.target.result);

    };
};

export const stringHtmlDataToDraftState = stringHtml => {
    const blocksFromHTML = convertFromHTML(stringHtml)
    return EditorState.createWithContent(ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
    ))
}

export const isArray = v_Valor => {
    return Array.isArray(v_Valor);
};

export const ArrayToMultiSelect = v_Array => {
    var v_retorno = "";

    if (v_Array) {
        if (isArray(v_Array)) {
            for (var v_i = 0; v_i < v_Array.length; v_i++) {
                if (isArray(v_Array[v_i])) {
                    for (var v_j = 0; v_j < v_Array[v_i].length; v_j++) {
                        v_retorno += v_Array[v_i][v_j] + "|";
                    }

                    v_retorno += ";"
                }
                else {
                    v_retorno += v_Array[v_i] + "|;";
                }
            }
        }
    }

    return v_retorno;
}

export const concatenarDropdown = itens => {
    if (itens.length <= 0) return "";
    let valores = [];
    itens.forEach(item => {
        valores.push(item.value);
    })
    return ArrayToMultiSelect(valores);
}



export const concatenaLojas = lojasSelecionadas => {
    if (lojasSelecionadas.length <= 0) return "";
    let lojasConcatenadas = "";
    for (let intLoja = 0; intLoja < lojasSelecionadas.length; intLoja++) {
        lojasConcatenadas += lojasSelecionadas[intLoja].value;
    }
    return lojasConcatenadas;
}

export const zeroEsquerda = function (v_Valor, v_Tamanho) {
    v_Valor = v_Valor.toString();
    while (v_Valor.length < v_Tamanho)
        v_Valor = 0 + v_Valor;
    return v_Valor;
};

export const toDateSQL = function (v_Valor) {
    if (typeof v_Valor == "string") {
        if (v_Valor.substring(2, 3) == "/" && v_Valor.substring(5, 6) == "/")
            return v_Valor.substring(6, 11) + "-" + v_Valor.substring(3, 5) + "-" + v_Valor.substring(0, 2);
        else if (v_Valor.substring(4, 5) == "-" && v_Valor.substring(7, 8) == "-")
            return v_Valor;
    }
    else {
        return v_Valor.getFullYear() + "-" + zeroEsquerda(v_Valor.getMonth() + 1, 2) + "-" + zeroEsquerda(v_Valor.getDate(), 2);
    }
};

export function toDateJS(ano, mes, dia) {
    return new Date(ano, parseInt(mes) - 1, dia ? dia : 1)
}

export const toDate = function (v_Valor) {
    if (!v_Valor)
        return null;
    if (typeof v_Valor == "string") {
        if (v_Valor.substring(4, 5) == "-" && v_Valor.substring(7, 8) == "-")
            return v_Valor.substring(8, 10) + "/" + v_Valor.substring(5, 7) + "/" + v_Valor.substring(0, 4);
        else if (v_Valor.substring(2, 3) == "/" && v_Valor.substring(5, 6) == "/")
            return v_Valor;
    }
    else {
        return zeroEsquerda(v_Valor.getDate(), 2) + "/" + zeroEsquerda(v_Valor.getMonth() + 1, 2) + "/" + v_Valor.getFullYear();
    }
};

export const Mes = function (v_Data) {
    return toDate(v_Data ? v_Data : new Date).substring(3, 5);
}

export const Ano = function (v_Data) {
    return toDate(v_Data ? v_Data : new Date).substring(6, 12);
};

export const Hoje = function () {
    return new Date;
}

export const RetornaPeriodoAnterior = (mes, ano) => {
    if (mes == 1) {
        mes = 12
        ano = ano - 1;
    } else {
        mes = mes - 1;
        ano = ano;
    }
    return { mes, ano };
}

export const primeiroDiaMes = function (v_Mes) {
    if (!v_Mes) {
        v_Mes = Mes();
    }
    v_Mes = zeroEsquerda(toInt(v_Mes), 2);
    return new Date(v_Mes + "/01/" + Ano());
}

export const toInt = function (v_Valor) {
    if (typeof v_Valor == "boolean") {
        v_Valor = Se(v_Valor, 1, 0);
    }
    if (typeof v_Valor == "string") {
        while (v_Valor.indexOf(".") != -1)
            v_Valor = v_Valor.replace(".", "");
    }
    return Se((v_Valor == "" || v_Valor == null), 0, parseInt(v_Valor));
};

export const Se = function (v_Condicao, v_Verdadeiro, v_Falso, v_executarFuncao) {
    if (v_Condicao) {
        if (isFunction(v_Verdadeiro) && (v_executarFuncao != false)) {
            return v_Verdadeiro();
        }
        else {
            return v_Verdadeiro;
        }
    }
    else {
        if (isFunction(v_Falso) && (v_executarFuncao != false)) {
            return v_Falso();
        }
        else {
            return v_Falso;
        }
    }
};

export const isFunction = function (f_Function) {
    return (typeof f_Function) == "function";
};

export const formatarColunaPercentualValor = (percentual, valor, usaCifrao = false, destacarNegativos = true, negrito = false) => {
    if (percentual == 0 && parseFloat(valor) == 0) return "-";


    let valorPercentual = "";

    if (percentual != 0) {
        valorPercentual = `<br /><small class="${negrito ? "text-bold" : ""}">${toMoney(percentual, 1)}%</small>`;
    }

    return `<div class="text-right ${negrito ? "text-bold" : ""} ${destacarNegativos && parseFloat(valor) < 0 ? "text-danger" : ""}">${usaCifrao ? "R$" : ""} ${valor}${valorPercentual}</div>`
}

export const formatarColunaPercentualValorClicavel = (percentual, valor, usaCifrao = false, destacarNegativos = true, negrito = false) => {
    if (percentual == 0 && parseFloat(valor) == 0) return "-";


    let valorPercentual = "";

    if (percentual != 0) {
        valorPercentual = `<br /><small class="${negrito ? "text-bold" : ""}">${toMoney(percentual, 1)}%</small>`;
    }

    //return `<div style="cursor:pointer;" class="text-right ${negrito ? "text-bold" : ""} ${destacarNegativos && parseFloat(valor) < 0 ? "text-danger" : ""}">${usaCifrao ? "R$" : ""} ${valor}${valorPercentual}</div>`
    return `<div class="text-right ${negrito ? "text-bold" : ""} ${destacarNegativos && parseFloat(valor) < 0 ? "text-danger" : ""}">${usaCifrao ? "R$" : ""} ${valor}${valorPercentual}</div>`
}

export const formatarFooterPercentualValorUnicaLinha = (percentual, valor, variacao, usaCifrao = false, destacarNegativos = true, negrito = false, usa_badge = false) => {
    if (percentual == 0 && parseFloat(valor) == 0) return `<div class="w-100 text-right">-</div>`;


    let valorPercentual = `<small class="w-100 ${negrito ? "text-bold" : ""} ml-1">${parseFloat(percentual) != 0 ? toMoney(percentual, 1) + "%" : "-"}</small>`;


    let variacao_mostrar = ``;
    if (variacao != "")
        variacao_mostrar = `
        <div class="d-flex w-100">
                            <span class="badge badge-soft-${variacao > 0 ? "success" : variacao < 0 ? "danger" : "warning"}" style="height: 15px;">
                              ${toMoney(variacao, 1)}%
                            </span>
                            `


    return `
            <div class="d-flex w-100">
                <div class="text-right ${negrito ? "text-bold" : ""} ${destacarNegativos && parseFloat(valor) < 0 ? "text-danger" : ""} text-truncate" style="flex:1;"> 
                    ${usaCifrao ? "R$" : ""} ${valor}
                </div>
                <div class="align-items-center d-flex text-right text-truncate" style="width:35px;height:18px;">
                    ${valorPercentual}
                </div>
            </div>`
}

export const formatarColunaPercentualValorUnicaLinha = (percentual, valor, variacao, usaCifrao = false, destacarNegativos = true, negrito = false, usa_badge = false) => {
    if (percentual == 0 && parseFloat(valor) == 0) return `<div class="w-100 text-right">-</div>`;


    let valorPercentual = `<small class="w-100 ${negrito ? "text-bold" : ""} ml-1">${parseFloat(percentual) != 0 ? toMoney(percentual, 1) + "%" : "-"}</small>`;


    let variacao_mostrar = ``;
    if (variacao != "")
        variacao_mostrar = `
        <div class="d-flex w-100">
                            <span class="badge badge-soft-${variacao > 0 ? "success" : variacao < 0 ? "danger" : "warning"}" style="height: 15px;">
                              ${toMoney(variacao, 1)}%
                            </span>
                            `


    return `
            <div class="d-flex w-100">
                <div class="text-right ${negrito ? "text-bold" : ""} ${destacarNegativos && parseFloat(valor) < 0 ? "text-danger" : ""}" style="flex:1;"> 
                    ${usaCifrao ? "R$" : ""} ${valor}
                </div>
                <div class="align-items-center d-flex text-right ${destacarNegativos && parseFloat(percentual) < 0 ? "text-danger" : ""}" style="width:37px;">
                    ${valorPercentual}
                </div>
            </div>`
}
export const formatarColunaMultiplasLinhas = (linhas, alterarCor = true) => {
    let linha = ``;
    if (linhas[0] != null)
        linha += `<div class="align-items-center d-flex w-100 ${linhas[1] != null ? "pt-2 pb-2" : ""} " ${alterarCor ? `style="color:#999a9b;"` : ""}>${linhas[0]}</div>`
    if (linhas[1] != null)
        linha += `<div class="align-items-center d-flex w-100 ${linhas[0] != null ? "pb-2" : ""}" ${alterarCor ? `style="color:#000;"` : ""} >${linhas[1]}</div>`

    return `<div class="d-flex flex-wrap w-100">${linha}</div>`;
}

export const formatarFooterMultiplasLinhas = (linhas, alterarCor = true) => {
    let linha = ``;

    linha += `<div class="align-items-center d-flex w-100 pt-2 pb-2" ${alterarCor ? `style="color:#999a9b;"` : ""}>${linhas[0]}</div>`
    linha += `<div class="align-items-center d-flex w-100 pb-2" ${alterarCor ? `style="color:#000;"` : ""} >${linhas[1]}</div>`

    return `<div class="d-flex flex-wrap w-100">${linha}</div>`;
}

export const formatarFooterTituloLinhas = (valor, titulo, variacao, id = "") => {

    return `
            <div class="d-flex align-items-center">
                <div class="${variacao == 0 ? "" : variacao > 0 ? "setaCima" : "setaBaixo"}" style="padding-left:1em;">
                </div>
                <div class="w-100">   
                    <span class="font-size-10" id="${id}">${titulo || ""}</span>
                    <br>
                    ${valor == 0 ? "-" : valor}
                </div>
            </div>
           `
}

export const formatarColunaDoisValores2 = (valor, valor1, usaCifrao = false, destacarNegativos = true, negrito = false) => {
    if (parseFloat(valor1.replace("R$", "").replace("%", "").trim()) == 0 && parseFloat(valor.replace("R$", "").replace("%", "").trim()) == 0) return "-";


    let valor1Label = "";

    if (parseFloat(valor1) != 0) {
        valor1Label = `<br /><small class="${negrito ? "text-bold" : ""}">${valor1}</small>`;
    }

    return `<div class="text-right ${negrito ? "text-bold" : ""} ${destacarNegativos && parseFloat(valor) < 0 ? "text-danger" : ""}">${usaCifrao ? "R$" : ""} ${valor}${valor1Label}</div>`
}

export const formatarColunaClassCss = (classe, valor) => {
    return `<div class="${classe}">${valor}</div>`
}


export const formatarColunaDoisValores = (valor1, valor2, usaCifrao = false, destacarNegativos = true) => {
    if (valor1 == 0 && valor2 == 0) return "-";

    let labelValor1 = valor1 > 0 ? toMoney(valor1, 0) : "-";
    let labelValor2 = `<br /><small>${valor2 > 0 ? toMoney(valor2, 0) : "-"}</small>`;

    return `<div class="text-right">${usaCifrao ? "R$" : ""} ${labelValor1}${labelValor2}</div>`
}

export const formatarColunaTresValores = (valor1, valor2, valor3, usaCifrao = false, destacarNegativos = true) => {
    if (valor1 == 0 && valor2 == 0 && valor3 == 0) return "-";

    let labelValor1 = `<div style="font-size:15px;" class="text-dark">${parseFloat(valor1.replace("R$", "")) > 0 ? valor1 : "-"}</div>`;
    let labelValor2 = `<div style="font-size:14px;" ">${parseFloat(valor1.replace("R$", "")) > 0 ? valor2 : "-"}</div>`;
    let labelValor3 = `<div style="font-size:12px;" >${parseFloat(valor3.replace("R$", "")) > 0 ? valor3 : "-"}</div>`;
    return `<div class="text-right">${usaCifrao ? "R$" : ""} ${labelValor1}${labelValor2}${labelValor3}</div>`
}


export const formatarFooterColunaPercentualValor = (percentual, valor, usaCifrao = false, casasDecimais = 0) => {
    if (percentual == 0 && valor == 0) return "-";

    let htmlPercentual = '';

    if (percentual > 0) {
        htmlPercentual = `
                    <div style="margin-top: -5px;">
                        <small>${toMoney(percentual, 1)}%</small>
                    </div>
                    `
    }

    return `<div class="text-right">
                <div><strong>${usaCifrao ? "R$" : ""} ${toMoney(valor, casasDecimais)}</strong></div>
                ${htmlPercentual}
            </div>`
}

export const formatarFooterColunaDoisValores = (valor, valor1, usaCifrao = false, casasDecimais = 0, casasDecimais1 = 0, simbolo1 = "") => {
    if (valor1 == 0 && valor == 0) return "-";

    let htmlValor1 = '';

    if (valor1 > 0) {
        htmlValor1 = `
                    <div style="margin-top: -5px;">
                        <small>${toMoney(valor1, casasDecimais1)} ${simbolo1}</small>
                    </div>
                    `
    }

    return `<div class="text-right">
                <div><strong>${usaCifrao ? "R$" : ""} ${toMoney(valor, casasDecimais)}</strong></div>
                ${htmlValor1}
            </div>`
}

export const formatarFooterTitulo = (valor, titulo, quebrarLinha = false) => {

    return `   
            <span class="font-size-10">${titulo || ""}</span>
            <br>
            ${quebrarLinha ? "<br />" : ""}
            ${valor == 0 ? "-" : valor}
           `
}

const toFloat = function (v_Valor) {
    if (v_Valor === null || v_Valor === undefined || v_Valor == "")
        v_Valor = 0;

    if (typeof v_Valor == "string" && v_Valor.indexOf(",") >= 0) {
        while (v_Valor.indexOf(".") > 0)
            v_Valor = v_Valor.replace(".", "");
        v_Valor = v_Valor.replace(",", ".");
    }
    else if (typeof v_Valor == "boolean") {
        v_Valor = Se(v_Valor, 1, 0);
    }

    if (typeof v_Valor == "string" || typeof v_Valor == "number")
        return parseFloat(v_Valor);
    else
        alert("Você só pode converter uma string ou ou número em float, figura!");
};

export const toMoney = function (v_Valor, v_qtdCasasDecimais) {
    var v_Cents, v_Retorno = "0,00";
    var v_Negativo = 0;
    var v_Centavos = null;

    if (typeof v_Valor != "number") {
        v_Valor = toFloat(v_Valor);
    }

    if (v_Valor < 0) {
        v_Valor = Math.abs(v_Valor);
        v_Negativo = 1;
    }

    v_Valor = eval(v_Valor).toFixed(v_qtdCasasDecimais);

    var v_aux = v_qtdCasasDecimais == 2 ? 100 : (v_qtdCasasDecimais <= 4 ? 10000 : 1000000);

    v_Centavos = Math.floor((v_Valor * v_aux + 0.5) % v_aux);

    v_Valor = Math.floor((v_Valor * 10000 + 0.5) / 10000).toString();

    v_Cents = v_Centavos.toString();

    if (v_qtdCasasDecimais == 0) {
        v_Centavos = "";
    }
    else if (v_qtdCasasDecimais == 1) {
        v_Centavos = (v_Cents.length == 0) ? v_Centavos = "0" : v_Cents.substring(0, 1);
    }
    else if (v_qtdCasasDecimais == 2) {
        if (v_Cents.length == 1)
            v_Centavos = "0" + v_Centavos;
    }
    else if (v_qtdCasasDecimais == 3) {
        if (v_Cents.length == 1)
            v_Centavos = "00" + v_Centavos;
        if (v_Cents.length == 2)
            v_Centavos = "0" + v_Centavos;
    }
    else if (v_qtdCasasDecimais == 4) {
        if (v_Cents.length == 1)
            v_Centavos = "000" + v_Centavos;
        if (v_Cents.length == 2)
            v_Centavos = "00" + v_Centavos;
        if (v_Cents.length == 3)
            v_Centavos = "0" + v_Centavos;
    }
    else if (v_qtdCasasDecimais == 5) {
        if (v_Cents.length == 1)
            v_Centavos = "0000" + v_Centavos;
        if (v_Cents.length == 2)
            v_Centavos = "000" + v_Centavos;
        if (v_Cents.length == 3)
            v_Centavos = "00" + v_Centavos;
        if (v_Cents.length == 4)
            v_Centavos = "0" + v_Centavos;
    }
    else if (v_qtdCasasDecimais == 6) {
        if (v_Cents.length == 1)
            v_Centavos = "00000" + v_Centavos;
        if (v_Cents.length == 2)
            v_Centavos = "0000" + v_Centavos;
        if (v_Cents.length == 3)
            v_Centavos = "000" + v_Centavos;
        if (v_Cents.length == 4)
            v_Centavos = "00" + v_Centavos;
        if (v_Cents.length == 5)
            v_Centavos = "0" + v_Centavos;
    }

    if (v_Cents.length > v_qtdCasasDecimais) {
        v_Centavos = v_Cents.substring(0, v_qtdCasasDecimais);
    }

    for (var v_i = 0; v_i < Math.floor((v_Valor.length - (1 + v_i)) / 3); v_i++)
        v_Valor = v_Valor.substring(0, v_Valor.length - (4 * v_i + 3)) + '.' + v_Valor.substring(v_Valor.length - (4 * v_i + 3));

    if (v_Centavos != "")
        v_Retorno = v_Valor + ',' + v_Centavos;
    else
        v_Retorno = v_Valor;

    if (v_Negativo == 1)
        v_Retorno = '-' + v_Retorno;

    return v_Retorno;
};

export const valorEmMoeda = function (valor, comCifrao = false) {
    valor = valor || 0;
    return comCifrao ? `R$ ${moeda.format(valor)}` : moeda.format(valor);
}

export const valorEmInteiro = function (valor) {
    valor = valor || 0;
    return inteiro.format(Math.round(valor))
}

export function f_retornaRotuloCategoriaComparativo(v_valor) {
    function f_pegarDiaSemana(v_valor) {
        const datas = v_valor.split(" ", 2)
        const todasAsDatas = datas[0].split("/")
        return moment(`1${todasAsDatas[1]}/${todasAsDatas[0]}/${todasAsDatas[2]}`).format("ddd - DD/MM/YY")
    }

    return f_pegarDiaSemana(v_valor);

}

export const retornarDiaSemanaAbreviada = (data) => {
    var dataNova = moment(data);
    return dataNova.format('ddd');
}



export function pegarCores() {
    return {
        primary: getComputedStyle(document.getElementsByTagName("body")[0]).getPropertyValue('--primary').trim(),
        info: getComputedStyle(document.getElementsByTagName("body")[0]).getPropertyValue('--info').trim(),
        danger: getComputedStyle(document.getElementsByTagName("body")[0]).getPropertyValue('--danger').trim(),
        warning: getComputedStyle(document.getElementsByTagName("body")[0]).getPropertyValue('--warning').trim(),
        darker: getComputedStyle(document.getElementsByTagName("body")[0]).getPropertyValue('--darker').trim(),
        dark: getComputedStyle(document.getElementsByTagName("body")[0]).getPropertyValue('--dark').trim(),
        success: getComputedStyle(document.getElementsByTagName("body")[0]).getPropertyValue('--success').trim(),
        gray: getComputedStyle(document.getElementsByTagName("body")[0]).getPropertyValue('--gray').trim(),
        secondary: getComputedStyle(document.getElementsByTagName("body")[0]).getPropertyValue('--secondary').trim(),
    }
}

export function removerFocusNoBotao(botao) {
    botao.style.outline = "none";
    botao.style.boxShadow = "none";
}
export function replaceAll(v_Valor, v_RemoverDe, v_RemoverPara) {
    while (v_Valor.indexOf(v_RemoverDe) > -1) {
        v_Valor = v_Valor.replace(v_RemoverDe, v_RemoverPara);
    }
    return v_Valor;
}
export function returnaAlturaTotalElementos(elementos) {
    let total = 0;
    elementos.forEach((elemento, indice) => {
        let elementoProcurado = document.querySelector(elemento);
        if (elementoProcurado) {
            let css = getComputedStyle(document.querySelector(elemento));
            total += parseInt(replaceAll(css.height, "px", ""));
            total += parseInt(replaceAll(css.marginTop, "px", ""));
            total += parseInt(replaceAll(css.marginBottom, "px", ""));
            total += parseInt(replaceAll(css.paddingTop, "px", ""));
            total += parseInt(replaceAll(css.paddingBottom, "px", ""));
            total += parseInt(replaceAll(css.borderTopWidth, "px", ""));
            total += parseInt(replaceAll(css.borderBottomWidth, "px", ""));

        }
    });
    return total;
}
export function returnaTotalMedidasVerticais(elementos) {
    let total = 0;
    elementos.forEach((elemento, indice) => {
        let elementoProcurado = document.querySelector(elemento);
        if (elementoProcurado) {
            let css = getComputedStyle(document.querySelector(elemento));
            total += parseInt(replaceAll(css.marginTop, "px", ""));
            total += parseInt(replaceAll(css.marginBottom, "px", ""));
            total += parseInt(replaceAll(css.paddingTop, "px", ""));
            total += parseInt(replaceAll(css.paddingBottom, "px", ""));
            total += parseInt(replaceAll(css.borderTopWidth, "px", ""));
            total += parseInt(replaceAll(css.borderBottomWidth, "px", ""));

        }
    });
    return total;
}


export const showMedium = {
    large: { hidden: true },
    '*': {
        hidden: false
    }
}
export const hideMedium = {
    large: { hidden: false },
    '*': {
        hidden: true
    }
};
